import React, { Component, useContext } from 'react';
import { Row, Col, Card,Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import greenarrow from '../assets/others/Asset 18@2x.png'
import redarrow from '../assets/others/Asset 17@2x.png'
import './Visioncomp.css';
import './Process.css';
import cycle from '../assets/process/Asset 48@2x.png'
import slide11 from '../assets/process/Asset 59@2x.jpg'
import slide2 from '../assets/process/Asset 97@2x.jpg'
import slide3 from '../assets/process/Asset 117@2x.jpg'
import slide4 from '../assets/process/Asset 118@2x.jpg'
import slide5 from '../assets/process/Asset 119@2x.jpg'
import slide6 from '../assets/process/Asset 120@2x.jpg'
import slide7 from '../assets/process/Asset 121@2x.jpg'
import slide8 from '../assets/process/Asset 122@2x.jpg'
import slide9 from '../assets/process/Asset 123@2x.jpg'
import slide10 from '../assets/process/Asset 124@2x.jpg'

import logo1 from '../assets/process/Asset 105@2x.png'
import logo2 from '../assets/process/Asset 106@2x.png'
import logo3 from '../assets/process/Asset 107@2x.png'
import logo4 from '../assets/process/Asset 108@2x.png'
import logo5 from '../assets/process/Asset 109@2x.png'
import logo6 from '../assets/process/HALAL.png'

import { HashLink } from 'react-router-hash-link';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <img alt="image" className={className} style={{ display: "inline",width:"50px",height:"50px"}} src={greenarrow} onClick={onClick} width="100"/>

    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;

    return (
          <img alt="image" className={className} style={{display: "inline",width:"50px",height:"50px",zIndex:"1"}} src={redarrow} onClick={onClick} width="100"/>
    );
  }
  
class ProcessComponent extends Component {


    render(){

        const settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:true,
            autoplaySpeed:3000,
            nextArrow:<SampleNextArrow/>,
            prevArrow:<SamplePrevArrow/>
          };

        return(

            <>
            <Row style={{padding:"0%",marginTop:"5%"}} >
              <Col lg="12">
                <div style={
                { 
                    alignItems: "center",
                    justifyContent: "center",
                    display:"flex",
                    flexDirection:"column",
                    paddingTop:"0%",
                    paddingBottom:"0%",
                }}>
                
                <div style={{paddingTop:"2%",position:"static",marginTop:"2%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                <img alt="image" src={dots} width="4%"/> 
                </div>
                
                <div style={{marginTop:"2%",position:"relative"}}>
                <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'black',fontSize:"42pt"}}>Food Processing</h1>           
                </div>
                <div style={{textAlign:"center",padding:"3%"}}>
                    <img alt="image" src={cycle} width="60%" />
                </div>

              
                </div>
                </Col> 

                </Row>
                <Row style={{padding:"2% 10%",alignItems:"center",textAlign:"center"}}>
                    <Col xs="12" lg="3"  className="column-space text-center zoom ">
                        <HashLink to='#Food'>
                        <div style={{padding:"2% ",fontSize:"35%"}} className="mont-med text-center column-red">
                            Food Processing
                        </div>
                        </HashLink>
                    </Col>
                    
                    <Col xs="12"  lg="3" className="column-space text-center zoom ">
                        <HashLink to='#Quality'>
                        <div href="#Quality"  style={{padding:"2%"}} className="mont-med text-center column-green">
                            Quality Control
                        </div>
                        </HashLink>
                    </Col>
                    
                    <Col xs="12"  lg="3" className="column-space text-center zoom ">
                    <HashLink to='#facility'>
                        <div style={{padding:"2% 15%"}}  className="mont-med text-center column-yellow">
                            Facility
                        </div>
                    </HashLink>

                    </Col>

                    <Col xs="12"  lg="3" className="column-space text-center zoom ">
                    <HashLink to='#Certifications'>
                        <div style={{padding:"2.2% 10%"}}  className="mont-med text-center column-red">
                            Certifications
                        </div>
                    </HashLink>

                    </Col>
                </Row>
           

                {/*Carousel Starts here*/}
                <div style={{backgroundColor:"black",padding:"5% 20%",marginTop:"3%",textAlign:"center"}}>
                    <img alt="image" src={dots} width="7%" />
                    <h1 style={{fontFamily:"CrimsonText-Semibold",color:"white",textAlign:"center",fontSize:"250%",paddingBottom:"5%",paddingTop:"2%"}}>Technology & Quality Control</h1>
                    
                        <Slider {...settings}  style={{backgroundColor:"black",textAlign:"left",marginLeft:"10%",marginRight:"10%"}}>
    
                            <div>
                                <img alt="image" src={slide11} width="100%" />
                            </div>
                            <div>
                                <img alt="image" src={slide8} width="100%"/>
                            </div>
                            <div>
                                <img alt="image" src={slide9} width="100%"/>
                            </div>
                            <div>
                                <img alt="image" src={slide7} width="100%"/>
                            </div>
                            <div>
                                <img alt="image" src={slide3} width="100%"/>
                            </div>
                        
                        
                        </Slider>
                </div>

                {/*Green card Food processing starts here*/}
                <a id="Food"></a>
                <div  className="column-green" style={{padding:"5% 10%",color:"white"}}>
                <h1 style={{fontFamily:"CrimsonText-SemiBold",fontSize:"250%"}}>Food Processing</h1>
                <hr style={{height:"1%",color:"white",backgroundColor:"white"}}></hr>
                <p style={{fontFamily:"Montserrat-Medium",fontSize:"12pt",padding:"2% 0%"}}>
                TGS facility is a well-made automated plant with adequate types of machinery 
                for proper food processing. It includes:
                </p>
                <Row>
                    <Col style={{fontSize:"150%"}}>
                    <ul>
                    <li>High Capacity Retort Plant</li>
                       
                    <li>Autoclaves</li>
                    <li>Sealing machines </li>
                    <li>Boilers </li>
                    </ul>
                    </Col>
                    <Col style={{fontSize:"150%"}}>
                    <ul>
                    <li>Pneumatic Gravity Fillers</li>
                    <li>In-house RO Plant</li>
                    <li>Full-fledged Effluent 
                      Treatment Plant</li>
                    </ul>
                    </Col>
                    <Col style={{fontSize:"150%"}}>
                    <ul>
                    <li> State-of-art kitchen   </li>
                    <li> High power DG sets </li>
                    <li> Other miscellaneous equipment.</li>
                    </ul>
                    </Col>
                </Row>
                <p style={{fontFamily:"Montserrat-Medium",fontSize:"12pt",padding:"0%",marginTop:"2%"}}>
                All these types of machinery ensure effective and efficient continuity in the manufacturing processes. The plant has a production capacity of 3 MT/day, scaling 
up as and when required. There are multiple superfluities built-in for all operations 
and equipment.
                </p>
                </div>


        {/*Yellow card Quality control starts here*/}
                <a id="Quality"></a>

                  <div className="column-yellow" style={{padding:"5% 10%",color:"black"}}>
                        <h1 style={{fontFamily:"CrimsonText-SemiBold",fontSize:"250%"}}>Quality Control</h1>
                        <hr style={{height:"1%",color:"black",backgroundColor:"black"}}></hr>
                        <p style={{fontFamily:"Montserrat-Medium",fontSize:"12pt",padding:"2% 0%"}}>
                        At TGS, we adhere to the highest quality standards right from procuring the ingredients & 
                        raw-materials to preparation and packaging.
                        </p>
                        <p style={{fontFamily:"Montserrat-Medium",fontSize:"12pt",paddingBottom:"2%"}}>
                        The quality control lab has various testing and 
                        other types of equipment for guaranteeing the high-grade quality of the food and
                        product prepared. It includes:
                        </p>
                        <Row>
                            <Col style={{fontSize:"150%"}}>
                            <ul>
                            <li> Laminar Flow</li>
                            <li> Microbiological Incubators</li>
                            <li> Colony Counters</li>
                            </ul>
                            </Col>
                            <Col style={{fontSize:"150%"}}>
                            <ul>
                            <li>Muffle Furnaces</li>
                            <li>Soxhlet Apparatus</li>
                            <li>Other miscellaneous equipment.</li>
                            </ul>
                            </Col>
                        </Row>
                        <p style={{fontFamily:"Montserrat-Medium",fontSize:"12pt",padding:"0%",marginTop:"2%"}}>
                        All employees who have a role in the food processing cycle undergo specialized training, 
                        maintain proper hygiene, wear appropriate and standard 
                        uniform in the process area and are supervised by highly experienced food technologists.
                        </p>

                </div>
              
               
                {/*Carousel Starts here*/}
                <a id="facility"></a>
                <div  style={{backgroundColor:"white",padding:"5% 25%",textAlign:"center",zIndex:"1000",marginBottom:"0%"}}>
                  
                    <img alt="image"  src={dots} width="7%" />
                    <h1 style={{fontFamily:"CrimsonText-Semibold",color:"black",textAlign:"center",fontSize:"250%",paddingBottom:"5%",paddingTop:"2%"}}>Facility</h1>
                    
                        <Slider {...settings}  style={{backgroundColor:"white",textAlign:"left"}}>
    
                            <div>
                                <img alt="image" src={slide2} width="100%"/>
                            </div>
                           
                            <div>
                                <img alt="image" src={slide4} width="100%"/>
                            </div>
                            <div>
                                <img alt="image" src={slide5} width="100%"/>
                            </div> <div>
                                <img alt="image" src={slide6} width="100%"/>
                            </div>
                            
                            <div>
                                <img alt="image" src={slide10} width="100%"/>
                            </div>
                        
                        </Slider>
                        <p style={{fontFamily:"Montserrat-Medium",textAlign:"left",fontSize:"12pt",padding:"8% 0% 2% 0%"}}>
                        Our manufacturing unit is situated in Agro Food Park, Boranada, Rajasthan. 
                        It spreads over an area of 2,100 sq m; the two-floor facility has the most modern 
                        cooking and packaging machines available in the world. These include 2 Retorts, an RO Plant, 
                        Cold Storage, a Boiler, Ovens, Kettles and other associated machinery. The entire production 
                        and packaging chain has redundancies to ensure that the production doesn’t stop if any technical 
                        glitch occurs. 
                        </p>
                        <p style={{fontFamily:"Montserrat-Medium",textAlign:"left",fontSize:"12pt"}}>
                        The unit is just 7 km from the Thar dry port, and thus the shipping of finished 
                        products is speedy and convenient. We are certified to export to countries including the USA, Canada, 
                        the UK, South Africa, Saudi Arabia, UAE, Australia and New Zealand amongst others.
                        </p>
                </div>

                {/* This is the certifications tile */}
                <a id="Certifications"></a>
                <div style={
                { 
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop:"0%",
                    paddingBottom:"0%",
                    backgroundColor:"#E3E3E3"
                }}>
                
                <div style={{paddingTop:"2%",position:"static",marginTop:"2%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                <img alt="image" src={dots} width="4%"/> 
                </div>
                
                <div style={{marginTop:"2%",position:"relative",textAlign:"center",paddingRight:"2%"}}>
                <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'black',fontSize:"250%"}}>Certifications & Standards</h1>           
                </div>
                <div style={{textAlign:"center",padding:"5% 0%"}}>
                    <Row style={{padding:"0% 8% 0% 10% "}}>
                        <Col lg="4" className="nopadding" style={{alignItems:"center",justifyContent:"center"}}>
                        <img alt="image" src={logo4} width="85%" />
                        </Col>
                        <Col lg="4"  style={{alignItems:"center",justifyContent:"center",padding:"1% 2% 1% 0%"}}>
                        <img alt="image" src={logo2} width="75%" />
                        </Col>
                        <Col lg="4" className="nopadding" style={{alignItems:"center",justifyContent:"center"}}  >
                        <img alt="image" src={logo5} width="70%" />
                        </Col>
                    </Row>
                    <Row style={{padding:"0% 8% 0% 10% ",marginTop:"1%"}}>
                        <Col lg="4"  style={{alignItems:"center",justifyContent:"center",marginTop:"3%",padding:"2% 0%"}}>
                        <img alt="image" src={logo1} width="80%" />
                        </Col>
                        <Col lg="4"  style={{alignItems:"center",justifyContent:"center",padding:"3% 0% 3% 0%"}}>
                        <img alt="image" src={logo3} width="85%" />
                        </Col>
                        <Col lg="4" style={{alignItems:"center",justifyContent:"center"}}  >
                        <img alt="image" src={logo6} width="50%" />
                        </Col>
                    </Row>
            
                </div>

              
                </div>

                      
            </>
        )
    }
}
export default ProcessComponent;