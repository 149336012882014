import React from 'react'
import { Component } from 'react';
import { Nav, Container, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { HashLink} from 'react-router-hash-link';
import navlogo from '../assets/1navheader/Asset 2@2x.png'


import './Header.css'
class HeaderComponent extends Component {
    render() {
        return (
            <div>
                <Navbar  fixed="top" collapseOnSelect expand="lg" style={{backgroundColor:"white",paddingTop:"1%",paddingBottom:"1%"}}>
                    <Container className="container-fluid">
                       <Link to="/">
                       <img alt="image" 
                            src={navlogo}
                            width="110"
                            height="50"
                            alt="TDC"    
                        ></img>
                       </Link>
                        
                        <Navbar.Toggle/>
                     

                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto text-center" >

                                <Link to='/Products/' >
                                    <div class="btn btn-one" >
                                        <span><Nav.Link    class="dropbtn fill" style={{ color: "black",display:"flex",alignItems:"center" }}>
                                           <div >
                                            <h3 style={{fontFamily:"Montserrat-Medium",fontSize:"80%",display:"inline"}}>
                                                <div className="circle-red"></div>
                                                &nbsp;&nbsp;PRODUCTS
                                            </h3>
                                            </div>

                                            </Nav.Link>
                                        </span>
                                    </div>
                                
                                </Link>


                                <Link to='/Story/'>

                                <div class="btn btn-one">
                                <span>      
                                    <Nav.Link class="dropbtn fill" style={{ color: "black",display:"flex",alignItems:"center" }}>
                                            <div>
                                                <h3 style={{fontFamily:"Montserrat-Medium",fontSize:"80%",display:"inline"}}>
                                                    <div className="circle-green"></div>
                                                    &nbsp;&nbsp;OUR STORY
                                                </h3>
                                            </div>
                                    </Nav.Link>
                                </span>
                                </div>
                                </Link>


                                <Link to='/Process/'>

                                <div class="btn btn-one" style={{borderTopColor:"",borderBottomColor:""}}>
                                <span><Nav.Link class="dropbtn fill" style={{ color: "black",display:"flex",alignItems:"center" }}>
                                           <div >
                                            <h3 style={{fontFamily:"Montserrat-Medium",fontSize:"80%",display:"inline"}}>
                                                <div className="circle-yellow"></div>
                                                &nbsp;&nbsp;OUR PROCESS
                                            </h3>
                                            </div>
                                            </Nav.Link></span>
                                    </div>
                                </Link>


                                <Link to='/Recipes/'>

                                <div class="btn btn-one">
                                <span><Nav.Link class="dropbtn fill" style={{ color: "black",display:"flex",alignItems:"center" }}>
                                           <div >
                                            <h3 style={{fontFamily:"Montserrat-Medium",fontSize:"80%",display:"inline"}}>
                                                <div className="circle-red"></div>
                                                &nbsp;&nbsp;RECIPES
                                            </h3>
                                            </div>
                                            </Nav.Link></span>
                                    </div>

                                </Link>



                                <Link to='/Privatelabel/'>
                                    <div class="btn btn-one">
                                    <span><Nav.Link class="dropbtn fill" style={{ color: "black",display:"flex",alignItems:"center" }}>
                                           <div >
                                            <h3 style={{fontFamily:"Montserrat-Medium",fontSize:"80%",display:"inline"}}>
                                                <div className="circle-green"></div>
                                                &nbsp;&nbsp;PRIVATE LABEL PROGRAM
                                            </h3>
                                            </div>
                                            </Nav.Link></span>
                                    </div>
                                </Link>
                                    

                                <HashLink to="/#Contact" >

                                    <div class="btn btn-one">
                                    <span><Nav.Link class="dropbtn fill" style={{ color: "black",display:"flex",alignItems:"center" }}>
                                           <div >
                                            <h3 style={{fontFamily:"Montserrat-Medium",fontSize:"80%",display:"inline"}}>
                                                <div className="circle-yellow"></div>
                                                &nbsp;&nbsp;CONTACT
                                            </h3>
                                            </div>
                                            </Nav.Link></span>
                                    </div>
                                </HashLink>
                            </Nav>
                        </Navbar.Collapse>

                    </Container>
                </Navbar>
            </div>
        )
    }
}

export default HeaderComponent