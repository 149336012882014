export const imageData = {
    "Curries":["Curries","Curries are synonymous primarily with Indian, and every region of India has its distinct curry flavour. Finding the right cooking sauce/pasta is the real secret to making a perfect curry. We create a benchmark line of TGS Curry Sauces/Pastes that will give you the most desirable curry preparation flavours from across the world. Whether you're a vegetarian or an ardent meat-eater, you can cook the best FINGER-LICKING curries through our curry bases."],
    "Ready to Eat":["Ready to Eat","TGS Foodz manufactures a wide range of Ready-To-Eat products – Dal Makhani, Palak Paneer, Paneer Butter Masala, Pindi Chole, Rajma Masala, Sarson ka Saag and more. Our products are in a league of their own – the fragrance, aroma and taste are unbeaten, and the freshness remains sealed for up to 18 months. The products go well with Basmati Rice and Indian Bread. The ingredients, packaging and design are all customizable as per your requirements."],
    "Combo Meals":["Combo Meals","Our Ready-to-Eat Combo Meals are a combination of traditional food selectively chosen from the Indian sub-continent. Rice with different Curries, Pasta with different Sauces, Manchurian with Noodles forms the staple of our combo meals. The authentic recipes from households and traditional Khansamas (master chefs) are hand-picked and technologically mastered at our HACCP certified plant keeping in mind both the Indian tradition and international standards."],
    "Marinades":["Marinades","Marinades are probably one of the essential ingredients in cookouts –  good marination will result in the preparation being tender and flavorful.  Marinades can be acidic (made with ingredients such as lemon juice or  vinegar) or enzymatic (made with ingredients such as pineapple or  ginger). In addition to these ingredients, marinades often contain oils,  spices and herbs for further flavouring. Besides the essential ingredients, Indian marinades like Tikka Marinade,  Tandoori Marinade and Kebab Marinade also have a generous mix of  spices. Our blend of ingredients that go into creating good marinades  shows our love and passion that we have for food, all homemade recipes  all right to the Indian Soul!"],
    "Vegan":["Vegan","Vegan is a plant-based protein and acts as a perfect substitute for vegetarians for experiencing non-vegetarian taste without changing their food habits. TGS Vegan Meals, specially engineered to cut non-veg food consumption, provides a great deal of food exposure with EXOTIC INDIAN textures and flavors."],
    "Cooking Sauces":["Cooking Sauces","We are an exporter of a wide range of Indian and Asian Sauces – the main categories being Simmer Sauces, Stir-Fry Sauces and Base Sauces. We customise designing and packing for our clients. Our line of sauces will  give you the best curry preparations from across the country. Whether you are a vegetarian or an avid meat-eater, you'll find our sauces irresistible."],
    "Condiments":["Condiments","Every country worldwide has its own set of condiments that give that novel  flavour, and Indian seasonings are no different. Whether it is chillies, chutneys, or pastes, Indian spices are in a league of their own. Feel free to pair them with our selection of Indian snacks or combine them with your recipe and create fusion cuisine. TGS Condiments have captured the unique taste of Indian  condiments and give the world an authentic TASTE OF INDIA."],
    "Frozen Products":["Frozen Snacks & Gravies","Curries are synonymous primarily with Indian, and every region of India has its distinct curry flavour. Finding the right cooking sauce/pasta is the real secret to making a perfect curry. We create a benchmark line of TGS Curry Sauces/Pastes that will give you the most desirable curry preparation flavours from across the world. Whether you're a vegetarian or an ardent meat-eater, you can cook the best FINGER-LICKING curries through our curry bases."],
    "Indian Sweets":["Indian Desserts","Indian desserts have always been very tempting and mouth watering. A sumptuous meal is incomplete without a sweet dish like Gulab Jamun or Halwa. TGS Foodz brings you a wide range of traditional Indian sweets garnished with exotic dry fruits that are sure  to tantalize your taste buds! "],

};