import React, { Component, useContext } from 'react';
import { Row, Col, Card,Accordion,AccordionContext,useAccordionToggle} from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css';
import {IoIosArrowUp,IoIosArrowDown} from 'react-icons/io'
import {faqData} from './data/faq.js'


function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button
        type="button"
        style={{backgroundColor:"black",padding:"0.2% 4%",fontSize:"125%",color:"white",display:"flex",alignItems:"center",justifyContent:"space-between"}}
        onClick={decoratedOnClick}
      >
        {children}<span style={{fontSize:"200%"}}>{!isCurrentEventKey?<IoIosArrowDown/>:<IoIosArrowUp/>} </span>
      </button>
    );
  }
  

class FaqComponent extends Component {


    render(){
        console.log(faqData);
        var arrowUp=false;
        return(

            <>
            <Row style={{padding:"0%",marginTop:"5%"}} >
              <Col lg="12">
                <div style={
                { 
                    alignItems: "center",
                    justifyContent: "center",
                    display:"flex",
                    flexDirection:"column",
                    paddingTop:"0%",
                    paddingBottom:"0%",
                }}>
                
                <div style={{paddingTop:"2%",position:"static",marginTop:"2%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                <img alt="image" src={dots} width="4%"/> 
                </div>
                
                <div style={{marginTop:"2%",position:"relative"}}>
                <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'black',fontSize:"42pt"}}>FAQs</h1>           
                </div>
              
                </div>
                </Col> 

                </Row>

                <div style={{marginTop:"5%",padding:"0% 10% 10% 10%"}}>
                <Accordion defaultActiveKey="0" >


                {   faqData.map((data) => (
                        <Card style={{cursor:"pointer",marginBottom:"0.5%",fontFamily:"Montserrat-Medium"}}>
                          
                            <ContextAwareToggle eventKey={data[0]}>                            
                                {data[1]} 
                            </ContextAwareToggle>

                            <Accordion.Collapse eventKey={data[0]}>
                            <Card.Body style={{backgroundColor:"#E3E3E3",padding:"4%"}}>{data[2]}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                                    
                    ))}
               
                </Accordion> 
                </div>         
            </>
        )
    }
}
export default FaqComponent;