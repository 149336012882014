import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import redarrow from '../assets/others/Asset 17@2x.png'
import greenarrow from '../assets/others/Asset 18@2x.png'


function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <img alt="image" className={className} style={{ display: "inline",width:"50px",height:"50px"}} src={greenarrow} onClick={onClick} width="100"/>

    );
}
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;

    return (
          <img alt="image" className={className} style={{display: "inline",width:"50px",height:"50px"}} src={redarrow} onClick={onClick} width="100"/>
    );
  }
  
class TermsComponent extends Component {
    constructor(props) {
        super(props);
       
    }
    render(){
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow:<SampleNextArrow/>,
            prevArrow:<SamplePrevArrow/>
          };
    
        return(

            <>
                    <Row style={{padding:"0%",marginTop:"5%"}}> 
                        <div style={
                        { 
                            
                            backgroundColor:"#E3E3E3",backgroundSize: "cover",width:'100%',height:'92vh',
                            alignItems: "center",
                            justifyContent: "center",
                            display:"flex",
                            flexDirection:"column",
                            paddingTop:"5%",
                            paddingBottom:"5%",
                        }}>
                        
                        <div style={{paddingTop:"2%",position:"static",marginTop:"2%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                        <img alt="image" src={dots} width="4%"/> 
                        </div>
                        
                        <div style={{marginTop:"2%",position:"relative"}}>
                        <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'black',fontSize:"40pt"}}>
                            Terms Of Use</h1>           
                        </div>
                        
                        <div style={{fontFamily:'Montserrat-Medium',color:'black',fontSize:"12pt",marginTop:"2%",paddingLeft:"25%",paddingRight:"25%"}}>
                        <p style={{textAlign:"left"}}>
                        Please read the following Terms of Use and Disclaimer before using 
                        the tgsfoodz.com Web site. By using our Web site, you agree to these Terms of Use. tgsfoodz.com reserves the right, at any time, to modify, alter or update these Terms of Use, and you agree to be bound by such modifications, alterations or updates from the time they are posted on our Web site.
                        It is your responsibility to check the Terms of Use periodically.
                        </p>                   
                        </div>

                        </div>
                    </Row>   
                  

                  {/*Carousel Starts here*/}
                  <div style={{backgroundColor:"black",padding:"5% 10%"}}>
                        <Slider {...settings}  style={{backgroundColor:"#ffcd00",padding:"5% 5%",textAlign:"left"}}>
                           
                            <div >
                                
                                <h1 style={{textAlign:"center",fontFamily:"Crimsontext-Regular",fontSize:"300%"}}>Copyright</h1>
                                <br></br>
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"5%",paddingLeft:"10%",paddingRight:"10%"}}>
                                All content included in our Web site, such as text, graphics, logos, button icons, 
                                images, and software, is the property of TGSFoodz Pvt. Ltd. or its content suppliers
                                 and protected by Indian and international copyright laws. 
                                 Permission is granted to electronically copy and print hard copy portions of tgsfoodz.com website for the sole purpose of placing an order with us or using the website as a shopping resource. Any other use, including the reproduction, modification, distribution, transmission, republication, display or performance, 
                                of the content of tgsfoodz.com or its respective content suppliers is strictly prohibited.
                                </p>

                            </div>
                            <div  >
    
                                <h1 style={{textAlign:"center",fontFamily:"Crimsontext-Regular",fontSize:"300%"}}>Disclaimer</h1>

                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"4%",paddingLeft:"5%",paddingRight:"5%"}}>
                                The information, services, products offered for sale and materials contained 
in and/or advertised on tgsfoodz.com website, including, without limitation, text, graphics and links, are provided on an “As Is” basis with no warranty. TO THE MAXIMUM EXTENT PERMITTED BY LAW, TGS Foodz Pvt. Ltd. AND ITS SUPPLIERS DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO SUCH INFORMATION, SERVICES, PRODUCTS AND MATERIALS, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS AND IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE. In addition, TGS Foodz Pvt. Ltd. and its suppliers do not represent or warrant that the information accessible via tgsfoodz.com is accurate, complete or current. We are not responsible for typographical errors. Price and availability information is subject to change without notice.

                                </p>

                            </div>
                            <div>
                                
                                <h1  style={{textAlign:"center",fontFamily:"Crimsontext-Regular",fontSize:"300%"}}>Trademarks</h1>
                                
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"10%",padding:"2% 31%"}}>
                                 All trademarks, logos, service marks and trade names that appear
                                 on this Web site are the trademarks of TGS Foodz Pvt. Ltd. and other respective owners.
                                </p>

                            </div>

                            <div >
                                
                                <h1  style={{textAlign:"center",fontFamily:"Crimsontext-Regular",fontSize:"300%"}}>User reviews & Other Comments</h1>
                                
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"5%",padding:"0% 5%"}}>
                                All reviews, comments and other submissions provided to tgsfoodz.com on or about this site, 
                                or otherwise submitted in connection with your use of the Web site, shall be and remain TGS Foodz Pvt Ltd.’s 
                                property. Such disclosure, submission or offer of any comments shall be deemed an assignment to TGS Foodz Pvt Ltd. of 
                                all worldwide rights in all intellectual properties relating to the comments.  
                                </p>
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"2%",padding:"0% 5%"}}>
                                You agree that no comments submitted by you to TGS Foodz Pvt 
                                Ltd. will violate any right of any third party, including copyrights, trademark, privacy or other personal or proprietary right(s). 
                                You further agree that no comments submitted by you to the site will be or contain libelous or otherwise unlawful, abusive or 
                                obscene material. You are and shall remain solely responsible for the content of any comments you make.
                               
                                </p>
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"2%",padding:"0% 5%"}}>
                                You agree that TGS Foodz Pvt Ltd. may use and/or disclose information consistent with our Privacy Policy.
                                </p>

                            </div>
                        </Slider>
                        </div>
                </>
        )
    }
}
export default TermsComponent;