import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Slider from 'react-slick';


import redarrow from '../assets/others/Asset 17@2x.png'
import greenarrow from '../assets/others/Asset 18@2x.png'
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import story1 from '../assets/story/Asset 50@2x.png'
import propic1 from '../assets/story/Asset 22@2x.png'
import propic2 from '../assets/story/Asset 27@2x.png'

//import img6 from '../assets/story/Asset 37@2x.png'
import img1 from '../assets/story/Asset 38@2x.png'
import img2 from '../assets/story/Asset 39@2x.png'
import img3 from '../assets/story/Asset 40@2x.png'
import img4 from '../assets/story/Asset 41@2x.png'
import img5 from '../assets/story/Asset 42@2x.png'

import './Visioncomp.css';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <img alt="image" className={className} style={{ display: "inline",width:"50px",height:"50px"}} src={greenarrow} onClick={onClick} width="100"/>

    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;

    return (
          <img alt="image" className={className} style={{display: "inline",width:"50px",height:"50px"}} src={redarrow} onClick={onClick} width="100"/>
    );
  }

  function CardSlides(props){
      return(
          <div style={{padding:"2%"}}>
           <img alt="image" src={props.id} width="100%" />
          </div>
      );

  }

const titles = [img1, img2, img4,img5];

class StoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          id: 0
        };
      }

    render(){



        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "0%",
            slidesToShow: 3,
            slidesToScroll: 1,
            useTransform:true,
            useCSS:true,  
            autoplay:true,
            autoplaySpeed:4000,  
            nextArrow:<SampleNextArrow/>,
            prevArrow:<SamplePrevArrow/>,
            beforeChange: (current, next) => this.setState({id:next}),

          };
        return(

            <>
                {/* This is 1st tile */}
                <Row style={{padding:"0%",marginTop:"5%"}}> 
                                <div style={
                                { 
                                    backgroundImage:`url('/images/story.jpg')` ,backgroundSize: "cover",width:'100%',height:'92vh',
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display:"flex",
                                    flexDirection:"column",
                                    paddingTop:"5%",
                                    paddingBottom:"5%",
                                }}>
                                
                                <div style={{paddingTop:"2%",position:"static",marginTop:"2%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                                <img alt="image" src={dots} width="4%"/> 
                                </div>
                                
                                <div style={{marginTop:"1%",position:"relative"}}>
                                <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"42pt"}}>Our Story</h1>           
                                </div>
                                
                                <div style={{fontFamily:'Montserrat-Medium',color:'whitesmoke',fontSize:"12pt",marginTop:"2%",paddingLeft:"26%",paddingRight:"26%"}}>
                                <p style={{textAlign:"left"}}>
                                TGS Foodz Pvt. Ltd. was born out of the expertise acquired over the years by The Grand Shubham
                                 Catering, a reputed catering service company known  for authentic Indian food for the last 20 years. 
                                </p>     

                                <p style={{textAlign:"left"}}>
                                TGS Foodz took the legacy forward by creating a new range of Sauces, Dips, Marinades, Cooking Pastes,
                                 and Ready-to-Eat for the global market. The  impeccable quality of our products has made them more
                                  delectable and  healthy. TGS Foodz stands 
                                for food that is natural, authentic, and delicious.  We ensure a ‘no preservative’ policy for all our products.  
                                </p> 

                                <p style={{textAlign:"left"}}>
                                Our state-of-the-art factory has extensive automation to produce 3MT of finished products 
                                for ambient conditions, per hour. We aim to be the leading manufacturer and preferred provider of processed foods by superior taste and innovative packaging. Our manufacturing facility has internationally 
                                recognized certifications for food quality viz HACCP, BRC, ISO 22000-2018, Halal, FSSAI, & USFDA.   
                                </p>               
                                </div>

                                </div>
                            </Row>       

                            {/* This is the 2nd tile*/}
                                <Card  className="column-yellow" style={{padding:"5% 22%",alignItems:"center"}}>
                                    <Card.Body>
                                        <Card.Title style={{textAlign:"center",fontSize:"250%",fontFamily:"CrimsonText-SemiBold"}}>
                                            Our Mission
                                        </Card.Title>
                                        <Card.Text style={{marginTop:"5%",justifyContent:"center",textAlign:"center",lineHeight:"115%",fontFamily:"CrimsonText-Regular",fontSize:"170%"}}>
                                        “We want to be the flag-bearers of quality, taste, trust,
                                        and pride by uniformly creating and delivering the best quality processed and packaged food.”
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                            {/* This is the 3rd tile */} 
                            <Row style={{padding:"0%"}}>
                                <div style={{position:"relative"}}>
                                <div id="transp">
                                    <h1 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"left",fontSize:"300%"}}>Our Vision</h1>
                                    
                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"left",marginTop:"10%"}}>
                                    We want to make boundless efforts to rigorously glorify Indian food and culture globally.
                                     We aim to garner consumer acclaim and love through our consistency in producing the utmost quality and taste.
                                    </p>
                                </div>
                                <img alt="image" src={story1} width="100%"></img>
                              
                                </div>
                               
                            </Row>      

                            {/* This is the 4th tile */}
                            <div style={{padding:"5% 5% 3% 5%",backgroundColor:"white",alignItems:"center"}}>
                                <div style={
                                { 
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display:"flex",
                                    wordWrap:"break-word",
                                    flexDirection:"column",
                                    width:"100%"
                                }}>

                                <div style={{width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                                <img alt="image" src={dots} width="5%"/> 
                                </div>
                                
                                <div style={{marginTop:"2%"}}>
                                <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'black',fontSize:"270%"}}>Our Team</h1>           
                                </div>

                                <Row style={{
                                            padding:"4%",margin:"2% 8% 2% 8%",backgroundColor:"#ff2e34",
                                            color:"white",alignItems:"center",
                                            fontFamily:"Montserrat-Medium"
                                            }}>
                                    <Col lg="5" style={{textAlign:"center"}}>
                                    <img alt="image" src={propic1} width="80%"></img>
                                    <br></br>
                                    <br></br>

                                    <h3 style={{fontFamily:"CrimsonText-SemiBold"}}>Mr. Rupesh Tatia</h3>
                                    <h6>Founder and Chairman</h6>
                                    <h6>Managing Director</h6>

                                    </Col>
                                    <Col lg="7" style={{paddingTop:"2%"}}>
                                    <p>
                                    Mr Rupesh Tatia is the driving force behind the TGS Foodz  Pvt. Ltd. A Management Postgraduate,
                                    he started his career by setting up roadside food stalls.  

                                    </p>

                                    <p>
                                    His vision expanded to owning one of 
                                     the best catering companies in India, TGS Shubham Caterers. As his catering company got outstanding 
                                     recognition; he developed further to bring authentic Indian cuisine to the forefront  of the world by 
                                     establishing a food processing company, TGS Foodz Pvt. Ltd.

                                    </p>

                                    <p>
                                     Passion Vista Global Icon Awards 2019 
                                     awarded him as the  'Most Admired Global Indian.' Confederation of International Accreditation Commission
                                      (CIAC) Global honoured him with a 'Doctor of Advanced Studies' for his excellence in The Field of Management.
                                      </p>

                                    </Col>
                                </Row>

                                <Row style={{
                                            padding:"4%",margin:"2% 8% 2% 8%",backgroundColor:"#00aa53",
                                            color:"white",alignItems:"center",
                                            fontFamily:"Montserrat-Medium"
                                            }}>
                                    <Col lg="5" style={{textAlign:"center"}}>
                                    <img alt="image" src={propic2} width="80%"></img>
                                    <br></br>
                                    <br></br>

                                    <h3 style={{fontFamily:"CrimsonText-SemiBold"}}>Mr. Ajay Kumar Jain</h3>
                                    <h6>Managing Director</h6>
                                    <h6>for Operation</h6>

                                    </Col>
                                    <Col lg="7" style={{paddingTop:"2%"}}>
                                    <p>
                                    Mr Ajay, a Law Graduate has great experience and knowledge of fine food and machines. 
                                     
                                    </p>

                                    <p>
                                    He has extensive hands-on expertise in creating authentic Indian cuisines; and the know-how about factory
                                      & machinery that go into mass producing these. He has been instrumental
                                     in setting up the manufacturing unit from scratch and effectively manages the overall operations of the company.
                                    </p>


                                    </Col>
                                </Row>
                                </div>
                            </div>


                            {/*Carousel Starts here*/}
                         <div style={{backgroundColor:"black",padding:"5% 15%"}}>
                               <Slider {...settings} className="center" style={{backgroundColor:"",padding:"1%",textAlign:"left"}}>
                                    
                               {titles.map((img, idx) => (
                                    <div className={idx === this.state.id ? "slide activeSlide" : "slide"}>
                                        <CardSlides id={img}/>
                                    </div>
                                    ))}
                               
                                    
                        </Slider>
                        </div>


            </>
        )
    }
}
export default StoryComponent;
