import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Slider from 'react-slick';


import redarrow from '../assets/others/Asset 17@2x.png'
import greenarrow from '../assets/others/Asset 18@2x.png'
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'

import recipes1 from '../assets/recipes/Asset 230@2x.jpg'
import recipes2 from '../assets/recipes/Asset 237@2x.jpg'
import recipes3 from '../assets/recipes/Asset 245@2x.jpg'
import recipes4 from '../assets/recipes/Asset 251@2x.jpg'
import recipes5 from '../assets/recipes/Asset 259@2x.jpg'



import './Visioncomp.css';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    if(props.hide!=props.currentSlide){
        return (
            <img alt="image" className={className} style={{ display: "inline",width:"50px",height:"50px"}} src={greenarrow} onClick={onClick} width="100"/>
    
        );
    }
    else{
        return (
            <>
            </>    
        ); 
    }
   
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    console.log(props.currentSlide);
    if(props.currentSlide==0){
        return( <>
        </>
        )
    }
    else{
        return (
            <img alt="image" className={className} style={{display: "inline",width:"50px",height:"50px"}} src={redarrow} onClick={onClick} width="100"/>
      );
    }
   
  }


class RecipesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstSlide:0,
            hideLast:false
        };
      }

    render(){



        const settings3 = {
            infinite: false,
            centerPadding: "0%",
            slidesToShow: 1,
            slidesToScroll: 1,   
            nextArrow:<SampleNextArrow hide={3}/>,
            prevArrow:<SamplePrevArrow currentSlide={this.state.firstSlide}/>,
          };

          const settings4 = {
            infinite: false,
            centerPadding: "0%",
            slidesToShow: 1,
            slidesToScroll: 1,   
            nextArrow:<SampleNextArrow hide={4}/>,
            prevArrow:<SamplePrevArrow currentSlide={this.state.firstSlide}/>,
          };

          const settings5 = {
            infinite: false,
            centerPadding: "0%",
            slidesToShow: 1,
            slidesToScroll: 1,   
            nextArrow:<SampleNextArrow hide={5}/>,
            prevArrow:<SamplePrevArrow currentSlide={this.state.firstSlide}/>,
          };
        return(

            <>
                {/* This is 1st tile */}
                <Row style={{padding:"0%",marginTop:"5%"}}> 
                                <div style={
                                { 
                                    backgroundImage:`url('/images/recipes.jpg')` ,backgroundSize: "cover",width:'100%',height:'92vh',
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display:"flex",
                                    flexDirection:"column",
                                    paddingTop:"7%",
                                    paddingBottom:"5%",
                                }}>
                                
                                <div style={{paddingTop:"0%",position:"static",marginTop:"0%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                                <img alt="image" src={dots} width="4%"/> 
                                </div>
                                
                                <div style={{marginTop:"1%",position:"relative"}}>
                                <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"42pt"}}>Our Popular Product Recipes</h1>           
                                </div>
                                
                                <div style={{fontFamily:'Montserrat-Medium',color:'whitesmoke',fontSize:"11pt",marginTop:"2%",paddingLeft:"26%",paddingRight:"26%"}}>
                                <p style={{textAlign:"left"}}>
                                Indian cuisine encompasses a wide variety of regional and traditional  cuisines native to India. 
                                Given the range of diversity in soil type, climate, culture, ethnic group and occupations, these
                                 cuisines vary significantly  from each other and use locally available spices, herbs, vegetables and  fruits. 
                                 Indian food is also heavily influenced by religious and cultural  choices and traditions. Indian cuisine has
                                  been and is still evolving, as  a result of the nation’s cultural interactions with other societies.  
                                </p>     

                                <p style={{textAlign:"left"}}>
                                TGS Foodz has prepared a wide range of sauces, pastes, dips, marinades, curries and more that you can use to create your own food preparations. Besides Indian cuisine, TGS also 
                                specializes in Asian, Mexican and Mediterranean food products. 
                                </p> 

                                <p style={{textAlign:"left"}}>
                                Below are some recipes prepared using our products. 
                                </p>               
                                </div>

                                </div>
                            </Row>       

                          
                            {/* This is the 2nd tile  ===========PANEER TIKKA==================*/} 
                            <Row style={{padding:"0%"}}>
                                <div style={{position:"relative"}}>
                                <div id="transpreci">
                                <Row style={{bottom:"0",position:"relative"}}>
                                <img alt="image" src={dots} width="12%" className="debugReci" style={{display:"block",margin:"0 auto",height:"3%"}}/>

                                </Row>
                                <Slider {...settings3} style={{padding:"0% 5% 2% 5%",height:"97%"}} className="debugReci">
                                                
                                                <div>
                                                    <h1 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%",marginTop:"40%"}}>Paneer Tikka Masala</h1>
                                                
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>

                                                </div>
                                                
                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"310%"}}>Paneer Tikka Masala</h4>
                                                
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Ingredients</h6>
                                                    <br></br>
                                                    <ul style={{paddingLeft:"30%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                        <li>½ cup - fresh cream  </li>
                                                        <li>3 tbsp - butter  </li>
                                                        <li>1 tbsp - vegetable oil </li>
                                                        <li>1 tbsp - red chili powder  </li>
                                                        <li>1 packet - TGS Tikka Masala  </li>
                                                        Simmer Sauce 
                                                        <li>3-4 cups - paneer cubes  </li>
                                                        <li>2 chopped onions </li>
                                                        <li>2 chopped tomatoes  </li>
                                                        <li>Water & Salt (accordingly) </li>
                                                    </ul>

                                                </div>

                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Paneer Tikka Masala</h4>
                                                
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Directions</h6>
                                                    <br></br>
                                                    <ol style={{padding:"0% 10%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                        <li> In a thick-bottomed pan, heat oil and butter. Add the chopped onions, then fry them until golden brown. </li>
                                                        <li> Add TGS Tikka Masala Simmer Sauce and stir fry. Add the tomatoes and water and gently boil for 5 minutes. </li>
                                                        <li> Add the paneer cubes and cook for 3-4 more minutes. </li>
                                                        <li> Add cream and blend well. Gently boil for 4 to 5 minutes or until the sauce has thickened and the paneer is soft and cooked. </li>
                                                        <li> Serve hot with different types of bread or rice.</li>
                                                    </ol>

                                                </div>


                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Paneer Tikka Masala</h4>
                                                    
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Chef's Suggestions</h6>
                                                    <br></br>
                                                    <h5 style={{fontFamily:"CrimsonText-Italic",textAlign:"center",fontSize:"300%"}}>“Paneer can be replaced with chicken or fish.”</h5>
                                                </div>
                                </Slider> 
                                

                                </div>
                                <img alt="image" src={recipes1} width="100%" height="100%"></img>

                                </div>
                               
                            </Row>                               




                             {/* This is the 3rd tile ===========DAL MAKHANI================== */} 

                             <Row style={{padding:"0%",marginTop:"3%"}}>
                                <div style={{position:"relative"}}>
                                <div id="transpreci">
                                <Row style={{bottom:"0",position:"relative"}}>
                                <img alt="image" src={dots} width="12%" className="debugReci" style={{display:"block",margin:"0 auto",height:"1%"}}/>
                                </Row>
                                <Slider {...settings4} className="debugReci" style={{padding:"0% 5% 2% 5%",height:"99%"}}>
                                                
                                                <div>
                                                    <h1 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%",marginTop:"40%"}}>Dal Makhani</h1>
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>

                                                </div>
                                                
                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"310%"}}>Dal Makhani</h4>
                                                
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Ingredients</h6>
                                                    <br></br>
                                                    <ul style={{paddingLeft:"30%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                    <li>¾ cup - black gram lentil  </li>
                                                    <li>¼ cup - red kidney beans  </li>
                                                    <li>½ cup - fresh cream  </li>
                                                    <li>3 tbsp - butter  </li>
                                                    <li>1 tbsp - vegetable oil </li>
                                                    <li>1 tsp - red chili powder  </li>
                                                    <li>4 tsp - TGS Makhani Sauce  </li>
                                                    <li>6 chopped garlic cloves  </li>
                                                    <li>2 chopped onions </li>
                                                    <li>2 chopped tomatoes  </li>
                                                    <li>Water & Salt (accordingly)</li>
                                                    </ul>

                                                </div>

                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Dal Makhani</h4>
                                                
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Directions</h6>
                                                    <br></br>
                                                    <ol style={{padding:"0% 10%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                    <li>  In 3-4 cups of water, soak the Gram Lentils & Kidney Beans overnight. </li>
                                                    <li> In the same broth, boil the soaked lentils and beans with salt and red chilli powder until cooked and smooth. </li>
                                                    <li> In a thick-bottomed pan, heat oil and butter. Add the chopped onions, then fry them until golden brown. </li>
                                                    <li> Add TGS Makhani Sauce, sliced tomatoes and garlic. Sauté the tomatoes once well mashed. To this, add boiled lentils and kidney beans. When stirring constantly, mash the mixture with the back of the spatula.</li>                                                      
                                                    </ol>

                                                </div>

                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Dal Makhani</h4>
                                                
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Directions</h6>
                                                    <br></br>
                                                    <ol start="5" style={{padding:"0% 10%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                    <li> If necessary, add some water and simmer for fifteen minutes on shallow heat. </li>
                                                    <li> Add the garam masala powder and fresh cream and let it stew for another five minutes. Finish off with a few pinches of powdered Kasoori Methi (dried fenugreek leaves). </li>
                                                    <li> Serve hot with different types of bread.</li>
                                                    </ol>

                                                </div>


                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Dal Makhani</h4>
                                                    
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Chef's Suggestions</h6>
                                                    <br></br>
                                                    <h5 style={{fontFamily:"CrimsonText-Italic",textAlign:"center",fontSize:"270%",padding:"0% 1%"}}>
                                                    “Replace the tomatoes with 4 tablespoons of thick tomato paste to enhance the taste and colour of the dish.”</h5>
                                                </div>
                                </Slider> 
                                
                                </div>
                                <img alt="image" src={recipes2} width="100%"></img>
                              
                                </div>
                               
                            </Row>           


                            {/* This is the 4th tile ===========Vegan hyderabadi Mutton biryani================== */} 

                            <Row style={{padding:"0%",marginTop:"3%"}}>
                                <div style={{position:"relative"}}>
                                <div id="transpreci">
                                <Row style={{bottom:"0",position:"relative"}}>
                                <img alt="image" src={dots} width="12%" className="debugReci" style={{display:"block",margin:"0 auto",height:"1%"}}/>
                                </Row>
                                <Slider {...settings4} style={{padding:"0% 5%",height:"99%"}}>
                                                
                                                <div>
                                                    <h1 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%",marginTop:"40%"}}>Vegan Hyderabadi</h1>
                                                    <h1 style={{fontFamily:"CrimsonText-SemiBold",lineHeight:"70%",textAlign:"center",fontSize:"300%"}}>Mutton Biryani</h1>

                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center",marginTop:"4%"}}>
                                                    Serves 4 people
                                                    </p>

                                                </div>
                                                
                                                <div>
                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Vegan Hyderabadi</h6>
                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",lineHeight:"70%",textAlign:"center",fontSize:"300%"}}>Mutton Biryani</h6>

                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center",marginTop:"4%"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Ingredients</h6>
                                                    <ul style={{paddingLeft:"30%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                        <li> ½ cup - yogurt  </li>
                                                        <li> 4 tbsp - vegetable oil </li>
                                                        <li> 1 packet - TGS Hyderabadi </li>
                                                         Biryani Paste 
                                                        <li> 1 ½ cup - basmati rice   </li>
                                                        <li> 2-3 cups - vegan mutton cubes  </li>
                                                        <li> 3 carrots cut in cubes  </li>
                                                        <li> 3 sliced onions  </li>
                                                        <li> 4-5 strands - saffron </li>
                                                        <li> 2 tbsp - chopped mint leaves  </li>
                                                        <li> 2 tbsp - chopped coriander leaves </li>
                                                        <li> 5 cashew nuts quartered </li>
                                                        <li> 10 almonds blanched and halved </li>
                                                        <li> Water & Salt (accordingly) </li>
                                                    </ul>

                                                </div>

                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Vegan Hyderabadi</h4>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",lineHeight:"70%",textAlign:"center",fontSize:"300%"}}>Mutton Biryani</h4>                                                
                                                    
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center",marginTop:"4%"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Directions</h6>
                                                    <ol style={{padding:"0% 10%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                        <li> Wash and soak rice in 3 cups of water for half an hour.  Drain and add 4 cups of water and salt. Bring to a boil and cook till the rice is 3/4th done. Drain excess water and spread out on a plate to cool.  </li>
                                                        <li>Boil vegan mutton and carrots in 2 cups of salted water. Drain and keep aside.  </li>
                                                        <li>Beat yoghurt and add 2 tbsp of TGS Hyderabadi Biryani Paste, half of the fried onions, ginger paste and garlic paste and parboiled vegan mutton and carrots.  </li>
                                                        <li>Allow marinating for one hour.</li>                                                     
                                                    </ol>

                                                </div>

                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Vegan Hyderabadi</h4>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",lineHeight:"70%",textAlign:"center",fontSize:"300%"}}>Mutton Biryani</h4>                                                
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center",marginTop:"4%"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Directions</h6>
                                                    <ol start="5" style={{padding:"0% 10%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                    <li> In a thick-bottomed pan, heat oil. Add the chopped onions, then fry them until golden brown. Add the marinated vegan mutton and sauté for 2-3 minutes. Add 3/4th cup of water and bring to a boil, then simmer for 2-3 minutes.  </li>
                                                    <li> Transfer the mix into a vessel—layer rice over it. Sprinkle the mint leaves, coriander leaves and the remaining fried onions. Garnish with cashew nuts, almonds and raisins. Place a moist cloth on top, cover with a lid and seal with dough and cook. </li>
                                                    <li> Serve hot after cooking.</li>
                                                    </ol>

                                                </div>


                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Vegan Hyderabadi</h4>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",lineHeight:"70%",textAlign:"center",fontSize:"300%"}}>Mutton Biryani</h4>                                                    
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center",marginTop:"4%"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Chef's Suggestions</h6>
                                                    <br></br>
                                                    <h5 style={{fontFamily:"CrimsonText-Italic",textAlign:"center",fontSize:"270%",padding:"0% 1%"}}>
                                                    “Vegan Mutton can be replaced with vegetables.”
                                                    </h5>
                                                </div>
                                </Slider> 
                                
                                </div>
                                <img alt="image" src={recipes3} width="100%"></img>
                              
                                </div>
                               
                            </Row>                        

                            

                             {/* This is the 3rd tile ===========VEG Jalfraize================== */} 

                             <Row style={{padding:"0%",marginTop:"3%"}}>
                                <div style={{position:"relative"}}>
                                <div id="transpreci">
                                <Row style={{bottom:"0",position:"relative"}}>
                                <img alt="image" src={dots} width="12%" className="debugReci" style={{display:"block",margin:"0 auto",height:"1%"}}/>
                                </Row>
                                <Slider {...settings3} style={{padding:"2% 5% 2% 5%",height:"99%"}}>
                                                
                                                <div>
                                                    <h1 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"310%",marginTop:"40%"}}>Veg Jalfraize</h1>
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>

                                                </div>
                                                
                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"310%"}}>Veg Jalfraize</h4>
                                                
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Ingredients</h6>
                                                    <br></br>
                                                    <ul style={{paddingLeft:"30%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                    <li> ½ cup - yogurt  </li>
                                                    <li> 1 tbsp - sunflower oil </li>
                                                    <li> 1 packet - TGS Jalfraize Sauce   </li>
                                                    <li> 700gm - diced vegetables </li>
                                                    (cauliflower, cabbage, carrot, french beans) 
                                                    <li> ½ cup - green peas  </li>
                                                    <li> 2 sliced onions </li>
                                                    <li> 200gm - chopped tomatoes </li>
                                                    <li> 2 red chillies (optional)</li>
                                                    <li> 100gm - sliced green/red bell peppers </li>
                                                    <li> 100ml - Water </li>
                                                    <li> Salt (accordingly) </li>
                                                    </ul>

                                                </div>

                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"310%"}}>Veg Jalfraize</h4>
                                                
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Directions</h6>
                                                    <br></br>
                                                    <ol style={{padding:"0% 10%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                        <li> In a medium saucepan, gently cook onions & bell pepper in sunflower oil with the lid on until well cooked, for 10 minutes.  </li>
                                                        <li>Mix 4 tablespoons of TGS Jalfraize paste and stir-fry for 3 minutes.  </li>
                                                        <li>Add the vegetables and sauté for 4-5 minutes.  </li>
                                                        <li>Add tomatoes and cook for 5 minutes.  </li>
                                                        <li>Add water and boil gently for 10 minutes until the vegetables have been well cooked. </li>
                                                        <li>Serve hot with Basmati rice or Indian bread.</li>
                                                    </ol>

                                                </div>

                                              


                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Veg Jalfraize</h4>
                                                    
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Chef's Suggestions</h6>
                                                    <br></br>
                                                    <h5 style={{fontFamily:"CrimsonText-Italic",textAlign:"center",fontSize:"270%",padding:"0% 1%"}}>
                                                    “Vegetables can be replaced with vegan protein, chicken, fish or lamb.”
                                                    </h5>
                                                </div>
                                </Slider> 
                                
                                </div>
                                <img alt="image" src={recipes4} width="100%"></img>
                              
                                </div>
                               
                            </Row>                



                            {/* This is the 4th tile ===========ALfredo Pasta================= */} 

                                    <Row style={{padding:"0%",marginTop:"3%",marginBottom:"3%"}}>
                                        <div style={{position:"relative"}}>
                                        <div id="transpreci">
                                        <Row style={{bottom:"0",position:"relative"}}>
                                        <img alt="image" src={dots} width="12%" className="debugReci" style={{display:"block",margin:"0 auto",height:"1%"}}/>
                                        </Row>
                                        <Slider {...settings4} style={{padding:"2% 5% 2% 5%",height:"99%"}}>
                                                        
                                                <div>
                                                    <h1 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%",marginTop:"40%"}}>Alfredo Pasta</h1>

                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>

                                                </div>
                                                
                                                <div>
                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Alfredo Pasta</h6>

                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Ingredients</h6>
                                                    <br></br>
                                                    <ul style={{paddingLeft:"25%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                        <li>3 cups - boiled pasta (fusilli,  </li>
                                                            penne, spaghetti, macaroni)
                                                        <li>1 bottle - TGS Alfredo Pasta Sauce  </li>
                                                        <li>2-3 cups - cubed onions </li>
                                                        <li>2-3 cups - cubed red/green/yellow </li>
                                                        bell peppers 
                                                        <li>3 tbsp - butter  </li>
                                                        <li>½ tsp - chopped garlic </li>
                                                        <li>¼ cup - cheddar cheese </li>
                                                        <li>2 tbsp - parmesan cheese  </li>
                                                        <li>1 tsp - dry chopped parsley </li>
                                                        <li>1 tsp - black pepper  </li>
                                                        <li>Water & Salt (accordingly) </li>
                                                    </ul>

                                                </div>

                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Alfredo Pasta</h4>
                                                    
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Directions</h6>
                                                    <br></br>

                                                    <ol style={{padding:"0% 10%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                        <li> Heat 4 cups water in a pan, add 1 tsp of salt and boil 1 cup of pasta until it has a slight bite.</li>
                                                        <li> Drain all the water and keep cooked pasta aside. </li>
                                                        <li> Heat 3 tablespoon butter and let it melt. </li>
                                                        <li> Keep flame to low or medium-low and then add ½ teaspoon finely chopped garlic. </li>
                                                        <li> Add the cube vegetables and a pinch of salt and stir well.  </li>
                                                        <li> Then add the cooked pasta. </li>
                                                        <li> Mix 4 tablespoons of TGS Alfredo Sauce Pasta and stir well until totally mixed with the vegetables. </li>                                                   
                                                    </ol>

                                                </div>

                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Alfredo Pasta</h4>
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Directions</h6>
                                                    <br></br>
                                                    <ol start="8" style={{padding:"0% 10%",fontSize:"11pt",fontFamily:"Montserrat-Medium"}}>
                                                        <li> Switch off the flame and add ¼ cup cheddar cheese and 2 tablespoons vegetarian parmesan cheese. </li>
                                                        <li> Lastly, add 1 teaspoon dry parsley and black pepper as required. Also, add some salt as per taste if required. You can also use fresh chopped parsley. </li>
                                                        <li> Serve alfredo sauce pasta garnished with some dried or fresh parsley and some grated parmesan cheese.</li>
                                                    </ol>

                                                </div>


                                                <div>
                                                    <h4 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"300%"}}>Alfredo Pasta</h4>
                                                    <p style={{fontFamily:"Montserrat-Medium",textAlign:"center"}}>
                                                    Serves 4 people
                                                    </p>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>

                                                    <h6 style={{fontFamily:"CrimsonText-SemiBold",textAlign:"center",fontSize:"200%"}}>Chef's Suggestions</h6>
                                                    <br></br>
                                                    <h5 style={{fontFamily:"CrimsonText-Italic",textAlign:"center",fontSize:"270%",padding:"0% 1%"}}>
                                                    “You can add a tasteful amount of chopped or sliced chicken, fish or lamb pieces in the pasta.”                                                    </h5>
                                                </div>
                                </Slider> 
                                
                                </div>
                                <img alt="image" src={recipes5} width="100%"></img>
                              
                                </div>
                               
                            </Row> 


            </>
        )
    }
}
export default RecipesComponent;