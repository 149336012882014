import React, { Component } from 'react';
import { Row, Col, Card ,Button} from 'react-bootstrap';
import { HashLink} from 'react-router-hash-link';


import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css'
import './Sauce.css'
import img1 from '../assets/cookingsauces/Asset 27@2x.png'
import img2 from '../assets/cookingsauces/Asset 28@2x.png'
import img3 from '../assets/cookingsauces/Asset 29@2x.png'
import img4 from '../assets/cookingsauces/Asset 30@2x.png'
import img5 from '../assets/cookingsauces/Asset 60@2x.png'
import img6 from '../assets/cookingsauces/Asset 59@2x.png'

import {sauceData} from './data/sauces_details';

class CookingComponent extends Component {

    render(){

        return(

            <>
                {/* This is 1st tile */}
                
                <Row style={{backgroundColor:"black",padding:"2% 10%",alignItems:"center"}}>
                    <Col xs="12" lg="3"  className="column-space text-center zoom">
                    <HashLink to='#Simmer'>
                        <Button id="1" value="Simmer Sauces" className="mont-med text-center column-yellow">
                            Simmer Sauces
                        </Button>
                    </HashLink>

                    </Col>
                    
                    <Col xs="12"  lg="3" className="column-space text-center zoom">
                    <HashLink to='#Hot'>
                        <Button id="2" value="Hot Sauces" className="mont-med text-center column-green">
                            Hot Sauces
                        </Button>
                    </HashLink>

                    </Col>
                    
                    <Col xs="12"  lg="3" className="column-space text-center zoom">
                    <HashLink to='#Stir'>

                        <Button href="#Stir" id="3" value="Stir Fry Sauces"  className="mont-med text-center column-red">
                            Stir Fry Sauces
                        </Button>

                    </HashLink>

                    </Col>

                    <Col xs="12" lg="3" className="column-space text-center zoom">
                    <HashLink to='#Pizza'>

                    <Button href="#Pizza" id="4" value="Pasta & Pizza"  className="mont-med text-center column-yellow">
                            Pasta & Pizza
                    </Button>

                    </HashLink>

                    </Col>
                </Row>

            <a id="Simmer" ></a>
            <Card  className="column-yellow" style={{padding:"5% 22%",alignItems:"center"}}>
                <Card.Body>
                    <Card.Title style={{textAlign:"center",fontSize:"250%",fontFamily:"CrimsonText-SemiBold"}}>
                        Simmer Sauces
                    </Card.Title>
                    <Card.Text style={{marginTop:"5%",justifyContent:"center",textAlign:"left",fontFamily:"Montserrat-Medium"}}>
                        {sauceData['Simmer Sauces']}
                    </Card.Text>
                </Card.Body>
            </Card>

                {/* This is 2nd tile */}

                <Row style={{padding:"6% 14%"}} >
                    <Col xs="12" sm="12" md="4" lg="3" >
                    <h1 style={{fontFamily:"CrimsonText-Regular"}}>Varieties</h1>
                    <br></br>
                    <br></br>

                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Indian</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li>All-Purpose Gravy </li>
                    <li>Bhuna Masala Simmer Sauce </li>
                    <li>Butter Masala Simmer Sauce </li>
                    <li>Chopped Onion Masala </li>
                    <li>Coconut Curry Simmer Sauce </li>
                    <li>Goan Curry Simmer Sauce </li>
                    <li>Jalfraize Simmer Sauce </li>
                    <li>Jodhpuri Dahiwala Masala Simmer Sauce </li>
                    <li>Kadhai Masala Simmer Sauce </li>
                    <li>Kalimiri Masala Simmer Sauce </li>
                    <li>Kashmiri Rogan Josh Simmer Sauce </li>
                    <li>Korma Masala Simmer Sauce </li>
                    <li>Lababdar Simmer Sauce </li>
                    <li>Madras Curry Simmer Sauce </li>
                    <li>Makhani Simmer Sauce  </li>
                    <li>Mango Naga Chilli Simmer Sauce </li>
                    <li>Red Curry Simmer Sauce </li>
                    <li>Tikka Masala Simmer Sauce </li>
                    <li>Tomato Masala Gravy</li>

                    </ul>

                    <br></br>
                    <br></br>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>International </h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li>Laksa Curry</li>
                    <li>Malaysian Penang Curry</li>
                    <li>Thai Green Curry Simmer Sauce</li>
                    <li>Thai Red Curry Simmer Sauce</li>
                    <li>Thai Yellow Curry Simmer Sauce</li>
                    <li>Vietnamese Lemongrass Sauce</li>
                  
                    </ul>

                   

                    </Col>
                    <Col xs="0" sm="0" md="1" lg="1">
                    </Col>
                    <Col xs="12" sm="12" md="8" lg="8" > 
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Row >
                        <Col className="nopadding">
                        <img alt="image" src={img1} width="100%" height="100%"/>
                        </Col>

                    </Row>
                    <Row  style={{marginTop:"2%"}} >                       
                        <Col xs="6" sm="6" md="6" lg="6" className="justify-content-center nopadding">
                        <img alt="image" src={img2} width="100%" height="100%"/>
                        </Col>
                        <Col xs="6" sm="6" md="6" lg="6" style={{paddingRight:"0%"}}>
                        <img alt="image" src={img3} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"2%"}}  >
                        <Col className="nopadding">
                        <img alt="image" src={img4} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    
                    </Col>
                    
                </Row>

                {/* This is 3rd tile */}
                <a id="Hot" ></a>
                <a id="Stir" ></a>


                <Row className="nopadding">

                    <Col id="Hot" xs="12" md="12" lg="6" className="nopadding column-green">

                    <div style={{padding:"8%",height:"60%"}}>
                    <div className="column-green" style={{padding:"5% 25%",color:"white"}}>
                        <h2 style={{textAlign:"center",padding:"5% 0%",fontFamily:"CrimsonText-SemiBold",fontSize:"250%"}}>Hot Sauces</h2>
                        <p style={{fontFamily:"Montserrat-Medium",marginTop:"7%"}}>
                            {sauceData['Hot Sauces']}
                        </p>
                    </div>
                    </div>
                   
                    <div  style={{backgroundColor:"white",padding:"8% 0% 5% 27%",marginRight:"3%",height:"38%"}}>
                        <Row >
                        <Col>
                        <h2 style={{fontFamily:"CrimsonText-Regular",textAlign:"left"}}>Varieties</h2>
                        <br></br>
                        <ul style={{paddingLeft:"inherit",textAlign:"left",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>

                        <li>Bhut Jolokia Pineapple Hot Sauce </li>
                        <li>Bird's Eye Red Chili Sauce </li>
                        <li>Hot & Sweet Honey Chili Sauce </li>
                        <li>Jalapeno Mexican Hot Sauce </li>
                        <li>Peri-Peri </li>
                        <li>Red Habanero </li>
                        <li>Smoky Chipotle Chili Sauce </li>
                        <li>Sriracha</li>
                                    
                        </ul>
                                                </Col>

                        </Row>

                    </div>                   
                
                    </Col>

                    <Col id="Stir" xs="12" md="12" lg="6"  className="nopadding column-red" >
                    <div style={{padding:"8%",height:"60%"}}>
                    <div className="column-red" style={{padding:"5% 25%",color:"white"}}>
                        <h2 style={{textAlign:"center",padding:"5% 0%",fontFamily:"CrimsonText-SemiBold",fontSize:"250%"}}>Stir Fry Sauces</h2>
                        <p style={{fontFamily:"Montserrat-Medium",marginTop:"7%"}}>
                            {sauceData['Stir Fry Sauces']}
                        </p>
                    </div>
                    </div>


                    <div  style={{backgroundColor:"white",padding:"8% 0% 5% 27%",marginLeft:"3%",height:"38%"}}>
                        <Row >
                        <Col>
                        <h2 style={{fontFamily:"CrimsonText-Regular",textAlign:"left"}}>Varieties</h2>
                        <br></br>
                        <ul style={{paddingLeft:"inherit",textAlign:"left",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                            
                        <li>Garlic & Black Pepper Stir Fry Sauce </li>
                        <li>Japanese Teriyaki Stir Fry Sauce </li>
                        <li>Mongolian Garlic Stir Fry Sauce </li>
                        <li>Schezwan Stir Fry Sauce </li>
                        <li>Teriyaki Hoisin Stir Fry Sauce </li>
                        <li>Thai Basil Stir Fry Sauce</li>
                    
                        </ul>
                        </Col>
                        </Row>
                    </div>
                    </Col>

                </Row>

                <a id="Pizza" ></a>

                {/* This is 4th tile */}

                <Card  className="column-yellow" style={{padding:"5% 22%",alignItems:"center"}}>
                <Card.Body>
                    <Card.Title style={{textAlign:"center",fontSize:"250%",fontFamily:"CrimsonText-SemiBold"}}>
                        Pizza & Pasta Sauces
                    </Card.Title>
                    <Card.Text style={{marginTop:"5%",justifyContent:"center",textAlign:"left",fontFamily:"Montserrat-Medium"}}>
                    {sauceData['Pasta & Pizza']}
                    </Card.Text>
                </Card.Body>
                </Card>
                
                {/* This div containes the 5th tile */}
                <Row style={{padding:"6% 12%"}}>
                    <Col xs="12" lg="4">
                    <h1 style={{fontFamily:"CrimsonText-Regular"}}>Varieties</h1>

                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        <br>
                        </br>
                        <li> Alfredo </li>
                        <li> Arrabbiata </li>
                        <li> Basil Pesto Sauce </li>
                        <li> BBQ Sauce </li>
                        <li> Black Bean Sauce </li>
                        <li> Bolognese Sauce </li>
                        <li> Enchilada Sauce </li>
                        <li> Marinara </li>
                        <li> White Sauce</li>

                    </ul>
                    </Col>
                    <Col sm xs="6" lg="4" style={{marginTop:"3%",padding:"0%"}}>
                    <img alt="image" src={img5} width="95%"/>
                    </Col>
                    <Col sm xs="6" lg="4" style={{marginTop:"3%",padding:"0%"}}>
                    <img alt="image" src={img6} width="95%"/>
                    </Col>
                </Row>

                
                {/* This div containes the 6th tile */}
                <div style={{padding:"5%",backgroundColor:"black",alignItems:"center"}}>
                    <div style={
                    { 
                        alignItems: "center",
                        justifyContent: "center",
                        display:"flex",
                        wordWrap:"break-word",
                        flexDirection:"column",
                        width:"100%"
                    }}>

                    <div style={{width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                    <img alt="image" src={dots} width="5%"/> 
                    </div>
                    
                    <div style={{marginTop:"1%"}}>
                    <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"270%"}}>Packaging Sizes</h1>           
                    </div>
                    </div>
                    <Row style={{backgroundColor:"black",alignItems:"center",marginTop:"2%",paddingLeft:"15%",paddingRight:"15%"}}>
                    <Col sm style={{paddingRight:"0%",paddingLeft:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#ff2e34",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                375 g
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Glass Bottle
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>


                    <Col sm style={{margin:"1%",paddingLeft:"0%",paddingRight:"0%",paddingTop:"2%",paddingBottom:"2%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#00aa53",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                1 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col sm style={{paddingLeft:"0%",paddingRight:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#ffcd00",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                2.5 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card></Col>

                </Row>
                    
                </div>
            </>
        )
    }
}
export default CookingComponent;