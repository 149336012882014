import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css'
import img1 from '../assets/curries/4. Tile Images/Asset 15@2x (with text).png'
import img2 from '../assets/curries/4. Tile Images/Asset 16@2x (with text).png'



class CurriesComponent extends Component {

    render(){

        return(

            <>
                <Row style={{padding:"6% 12%"}}>
                    <Col xs="12" lg="4">
                    <h1 style={{fontFamily:"CrimsonText-Regular"}}>Varieties</h1>

                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        <br>
                        </br>
                        <li>Red Gravy</li>
                        <li>Brown Gravy</li>
                        <li>Korma Masala Paste</li>
                        <li>Green (Palak) Gravy</li>
                        <li>Biryani Paste </li>
                        (Hyderabadi Style)
                        <li>Biryani Paste</li>
                        (Mumbai Style)
                        <li>Sambhar Paste</li>
                        <li>Madras Curry Paste</li>

                    </ul>
                    </Col>
                    <Col sm xs="6" lg="4" style={{marginTop:"3%",padding:"0%"}}>
                    <img alt="image" src={img1} width="95%"/>
                    </Col>
                    <Col sm xs="6" lg="4" style={{marginTop:"3%",padding:"0%"}}>
                    <img alt="image" src={img2} width="95%"/>
                    </Col>
                </Row>

                                
                {/* This div containes the 3rd tile */}
                <div style={{padding:"5%",backgroundColor:"black",alignItems:"center"}}>
                    <div style={
                    { 
                        alignItems: "center",
                        justifyContent: "center",
                        display:"flex",
                        wordWrap:"break-word",
                        flexDirection:"column",
                        width:"100%"
                    }}>

                    <div style={{width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                    <img alt="image" src={dots} width="5%"/> 
                    </div>
                    
                    <div style={{marginTop:"1%"}}>
                    <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"270%"}}>Packaging Sizes</h1>           
                    </div>
                    </div>
                    <Row style={{backgroundColor:"black",alignItems:"center",marginTop:"2%",paddingLeft:"15%",paddingRight:"15%"}}>
                    <Col sm style={{paddingRight:"0%",paddingLeft:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#ff2e34",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                375 g
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Glass Bottle
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>


                    <Col sm style={{margin:"1%",paddingLeft:"0%",paddingRight:"0%",paddingTop:"2%",paddingBottom:"2%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#00aa53",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                1 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col sm style={{paddingLeft:"0%",paddingRight:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#ffcd00",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                2.5 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card></Col>

                </Row>
                    
                </div>
            </>
        )
    }
}
export default CurriesComponent;