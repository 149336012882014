import React, { Component } from 'react';
import { Row,Col } from 'react-bootstrap';

import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'

import './Visioncomp.css'

import {imageData} from './data/image_data';

class ImageComponent extends Component {
    constructor(props){
        super(props);
        console.log(props.name);
        
        // console.log(this.curr_obj);    
    }

    render(){
        //  const cover = window.innerWidth >= 650 ? curries : curries_m;
        console.log(this.props)
        if(this.props.name != "Frozen Products")
        {
            return(


                <Row style={{padding:"0%",marginTop:"5%"}}> 
                <div style={
                { 
                    backgroundImage:`url('/images/${this.props.name}.jpg')` ,backgroundSize: "cover",width:'100%',height:'92vh',
                    alignItems: "center",
                    justifyContent: "center",
                    display:"flex",
                    flexDirection:"column",
                    paddingTop:"5%",
                    paddingBottom:"5%",
                }}>
                
                <div style={{paddingTop:"2%",position:"static",marginTop:"2%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                <img alt="image" src={dots} width="4%"/> 
                </div>
                
                <div style={{marginTop:"1%",position:"relative"}}>
                <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"42pt"}}>{imageData[this.props.name][0]}</h1>           
                </div>
                
                <div style={{fontFamily:'Montserrat-Medium',color:'whitesmoke',fontSize:"12pt",marginTop:"2%",paddingLeft:"25%",paddingRight:"25%"}}>
                   <p style={{textAlign:"left"}}>
                   {imageData[this.props.name][1]}
                   </p>                   
                </div>

                </div>
            </Row>
    
    )
        }
        else{
            return(
                <Row style={{padding:"0%",marginTop:"5%"}}> 
                <div style={
                { 
                    alignItems: "center",
                    justifyContent: "center",
                    display:"flex",
                    flexDirection:"column",
                    paddingTop:"0%",
                    paddingBottom:"0%",
                }}>
                
              
                
        
                <div style={
                { 
                    backgroundImage:`url('/images/${this.props.name}.png')` ,backgroundSize: "cover",width:'100%',height:"92vh",
                    alignItems: "center",
                    justifyContent: "center",
                    display:"flex",
                    flexDirection:"column",
                    paddingTop:"3%",
                    paddingBottom:"3%",
                    marginTop:"2%"
                }}
                
                >  
                
                <div style={{paddingTop:"0%",position:"static",marginTop:"0%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                <img alt="image" src={dots} width="4%"/> 
                </div>

                <div style={{fontFamily:'Montserrat-Medium',color:'whitesmoke',fontSize:"12pt",marginTop:"3%",paddingLeft:"20%",paddingRight:"20%"}}>
                   <Row>
                    <Col style={{paddingTop:"0%",paddingRight:"20%"}}>
                    <h1 style={{fontFamily:"CrimsonText-SemiBold",fontSize:"300%"}}> Frozen Snacks</h1>
                    <br></br>

                    <p>
                    Frozen Snacks are the frozen food products consumed with a little cooking effort like heating or frying. 
                    TGS Frozen Snacks offer a healthy and delicious snacking for people at an instant. 
                    These snacks can reasonably SATISFY your taste buds and GRACEFULLY PARTNER with your overall meals. 
                    </p>
                    </Col>
                    <Col style={{paddingTop:"0%",paddingLeft:"0%"}}>
                    <h1 style={{fontFamily:"CrimsonText-SemiBold",fontSize:"300%"}}> Frozen Gravies</h1>
                    <br></br>

                    <p>
                    Frozen Gravies are the frozen versions of  thickened and seasoned food base gravy preparation. 
                    TGS Frozen Gravies serve a  great purpose in saving cooking time and providing the same colour and flavour as  
                    regular food just with little heating. With  a remarkable shelf life, these gravies are  a FLAVOUR SOME SAVIOUR for human existence.  
                    </p>
                    </Col>   
                    </Row>               
                </div>  
                
                </div>


                </div>
                </Row>
            )
        }
        
    }
}
export default ImageComponent;