import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css'

import img1 from '../assets/marinades/Asset 25@2x.png'
import img2 from '../assets/marinades/Asset 26@2x.png'
import img3 from '../assets/marinades/Asset 60@2x.png'
import img4 from '../assets/marinades/Asset 28@2x.png'

class MarinadesComponent extends Component {

    render(){

        return(

            <>
                <Row style={{padding:"6% 12%"}}>
                    <Col xs="12" sm="4" md="4" lg="4">
                    <h1 style={{fontFamily:"CrimsonText-Regular"}}>Varieties</h1>
                    <br></br>
                    <br></br>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Indian</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li>Hyderabadi Green Marinade</li>
                    <li>Kashmiri Marinade </li>
                    <li>Kebab Marinade</li>
                    <li> Malai Tikka Marinade</li>
                    <li> Tandoori Marinade </li>
                    <li>Tikka Marinade</li>     

                    </ul>

                    <br></br>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Asian</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                       <li>Honey, Soy & Garlic</li>
                       Marinade
                       <li>Smokey BBQ Marinade</li>
                  
                    </ul>

                    <br></br>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Mediterranian</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                        <li>Shwarma Marinade</li>
                        <li>Shish Taouk Marinade</li>
                        
                    </ul>

                    </Col>
                    <Col xs="12" sm="12" md="7" lg="8" className="nopadding">
                    <br></br>

                    <Row className="nopadding">
                        <Col className="nopadding">
                        <img alt="image" src={img1} width="95%" height="95%"/>
                        </Col>
                        <Col className="nopadding">
                        <img alt="image" src={img2} width="95%" height="95%"/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"0%"}} className="nopadding">
                        <Col className="nopadding">
                        <img alt="image" src={img3} width="97.5%" height="100%"/>
                        </Col>
                    </Row>
                    
                    </Col>
                    
                </Row>

                                
                {/* This div containes the 3rd tile */}
                <div style={{padding:"5%",backgroundColor:"black",alignItems:"center"}}>
                    <div style={
                    { 
                        alignItems: "center",
                        justifyContent: "center",
                        display:"flex",
                        flexDirection:"column",
                        width:"100%"
                    }}>

                    <div style={{width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                    <img alt="image" src={dots} width="5%"/> 
                    </div>
                    
                    <div style={{marginTop:"1%"}}>
                    <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"270%"}}>Packaging Sizes</h1>           
                    </div>
                    </div>
                    <Row style={{backgroundColor:"black",alignItems:"center",marginTop:"2%",paddingLeft:"15%",paddingRight:"15%"}}>
                    <Col sm style={{paddingRight:"0%",paddingLeft:"0%"}}>
                    <Card  className="card-mod" style={{backgroundColor:"#ff2e34",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                375 g
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Glass Bottle
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>


                    <Col sm style={{margin:"1%",paddingLeft:"0%",paddingRight:"0%",paddingTop:"2%",paddingBottom:"2%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#00aa53",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                1 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col sm style={{paddingLeft:"0%",paddingRight:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#ffcd00",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                2.5 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card></Col>

                </Row>
                    
                </div>
            </>
        )
    }
}
export default MarinadesComponent;