import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css'

import img1 from '../assets/snacks/Asset 10@2x.png'
import img2 from '../assets/snacks/Asset 13@2x.png'

class SnackComponent extends Component {

    render(){

        return(

            <>
                <Row style={{padding:"6% 12%"}}>
                    <Col xs="12" sm="4" md="4" lg="4">
                    
                    <h1 style={{fontFamily:"CrimsonText-Regular",fontSize:"300%"}}>Snacks</h1>
                    <br></br>
                    <br></br>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li>Aloo Tikki </li>
                    <li>Cheese Corn Samosa </li>
                    <li>Cheese Jalapeno Samosa </li>
                    <li>Dahi Bhalla </li>
                    <li>Hara Bhara Kebab </li>
                    <li>Pizza Samosa </li>
                    <li>Punjabi Samosa </li>
                    <li>Veg Cocktail Samosa </li>
                    <li>Veg Cutlet </li>
                    <li>Veg Shami Kebab</li>    

                    </ul>

                    <br></br>
                    <br></br>

                    <h1 style={{fontFamily:"CrimsonText-Regular",fontSize:"300%"}}>Gravies</h1>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                    <br></br>
                    <br></br>


                    <li>Tawa Gravy </li>
                    <li>Butter Chicken Gravy </li>
                    <li>Tikka Masala Gravy </li>
                    <li>Roganjosh Gravy </li>
                    <li>Palak Gravy </li>
                    <li>Shahi Korma Gravy </li>
                    <li>Chettinad Gravy </li>
                    <li>Lababdar Gravy</li>
                  
                    </ul>

                    

                    </Col>
                    <Col xs="12" sm="12" md="7" lg="8" className="nopadding">
                    <br></br>

                    <Row className="nopadding">
                        <Col className="nopadding">
                        <img alt="image" src={img1} width="95%" height="95%"/>
                        </Col>
                        
                    </Row>
                    <Row style={{marginTop:"0%"}} className="nopadding">
                        <Col className="nopadding">
                        <img alt="image" src={img2} width="95%" height="95%"/>
                        </Col>
                    </Row>
                    
                    </Col>
                    
                </Row>

                                
                {/* This div containes the 3rd tile */}
                <div style={{padding:"5%",backgroundColor:"black",alignItems:"center"}}>
                    <div style={
                    { 
                        alignItems: "center",
                        justifyContent: "center",
                        display:"flex",
                        flexDirection:"column",
                        width:"100%"
                    }}>

                    <div style={{width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                    <img alt="image" src={dots} width="5%"/> 
                    </div>
                    
                    <div style={{marginTop:"1%"}}>
                    <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"270%"}}>Packaging Sizes</h1>           
                    </div>
                    </div>
                    <Row style={{backgroundColor:"black",alignItems:"center",marginTop:"2%",paddingLeft:"20%",paddingRight:"20%"}}>
                    <Col sm style={{paddingRight:"0%",paddingLeft:"0%"}}>
                    <Card  className="card-mod" style={{backgroundColor:"#ff2e34",alignItems:"center"}}>
                        <Card.Body style={{padding:"25% 20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                1 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                                Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>


                    <Col sm style={{margin:"1%",paddingLeft:"0%",paddingRight:"0%",paddingTop:"2%",paddingBottom:"2%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#00aa53",alignItems:"center"}}>
                        <Card.Body style={{padding:"25% 20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                2.5 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                   

                </Row>
                    
                </div>
            </>
        )
    }
}
export default SnackComponent;