import React, { Component } from 'react';
import { Row, Col} from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css';

import img1 from '../assets/products/Asset 17@2x.jpg'
import img2 from '../assets/products/Asset 18@2x.jpg'
import img3 from '../assets/products/Asset 19@2x.jpg'
import img4 from '../assets/products/Asset 20@2x.jpg'
import img5 from '../assets/products/Asset 21@2x.jpg'
import img6 from '../assets/products/Asset 22@2x.jpg'
import img7 from '../assets/products/Asset 23@2x.jpg'
import img8 from '../assets/products/Asset 24@2x.jpg'
import img9 from '../assets/products/Asset 25@2x.jpg'
import VisionComponent from './VisionComponent';
import { Link } from 'react-router-dom';


class ProjectComponent extends Component{
    constructor(props){
        super(props);
        console.log(props.id);
        console.log(props.cond);
        const page={
        1:"Curries",
        2:"Ready to Eat",
        3:"Frozen Products",
        4:"Marinades",
        5:"Vegan",
        6:"Indian Sweets",
        7:"Condiments",
        8:"Cooking Sauces",
        9:"Combo Meals",
    }
        console.log(props.cond);
        this.state={
            isproduct:props.cond,
            view:page[props.id]
        }
    }
    render(){
        if(this.state.isproduct == 'true'){

        return (
            <>
                        {/* This is the 1st tile */}
                        <Row style={{padding:"0%",marginTop:"5%"}}> 
                                <div style={
                                { 
                                    width:'100%',
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingTop:"5%",
                                    paddingBottom:"5%",
                                }}>
                                
                                <div style={{paddingTop:"0%",position:"static",marginTop:"0%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                                <img alt="image" src={dots} width="4%"/> 
                                </div>
                                
                                <div style={{marginTop:"1%",position:"relative",textAlign:"center"}}>
                                <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'black',fontSize:"42pt"}}>Our Products</h1>           
                                </div>
                        
                            <Row style={{padding:"0% 15%",marginTop:"3%"}}>
                                <Col lg="4" style={{padding:"0.5%"}} >
                                <Link to="/Products/8/"> 
                                <img alt="image"  width="100%" src={img1} />
                                </Link>
                                </Col>
                                
                                <Col lg="4" style={{padding:"0.5%"}} >
                                <Link to="/Products/7/"> 
                                <img alt="image"  width="100%" src={img2} />
                                </Link>
                                </Col>

                                <Col lg="4" style={{padding:"0.5%"}}>
                                <Link to="/Products/4/"> 
                                <img alt="image"  width="100%" src={img3} />
                                </Link>
                                </Col>

                            </Row>

                            <Row style={{padding:"0% 15%"}}>
                                <Col lg="6" style={{padding:"0.5%"}}>
                                <Link to="/Products/1/"> 
                                <img alt="image"  width="100%" src={img4} />

                                </Link>
                                </Col>
                                <Col lg="6" style={{padding:"0.5%"}}>
                                <Link to="/Products/9/"> 
                                <img  alt="image"  width="100%" src={img5} />
                                </Link>
                                </Col>
                            </Row>
                            
                            <Row style={{padding:"0% 15%"}}>
                                <Col lg="4" style={{padding:"0.5%"}}>
                                <Link to="/Products/2/"> 
                                <img alt="image"  width="100%" src={img6} />
                                </Link>
                                </Col>
                                <Col lg="4" style={{padding:"0.5%"}}>
                                <Link to="/Products/3"> 
                                <img alt="image"  width="100%" src={img7} />
                                </Link>
                                </Col>
                                <Col lg="4" style={{padding:"0.5%"}}>
                                <Link to="/Products/6/"> 
                                <img alt="image"  width="100%" src={img8} />
                                </Link>
                                </Col>
                                
                            </Row>
                            
                            <Row style={{padding:"0% 15%"}}>
                                <Col lg="12" style={{padding:"0.5%"}}>
                                <Link to="/Products/5/"> 
                                <img alt="image"  width="100%" src={img9} />
                                </Link>
                                </Col>
                                
                            </Row>

                        </div>
                    </Row>       
        </>
        )

    }
    else{
        return <VisionComponent name={this.state.view} />
    }
    }
}

export default ProjectComponent;