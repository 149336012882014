import React, { Component } from 'react';
import { Row, Col, Form,Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';
import { HashLink} from 'react-router-hash-link';

import emailjs from 'emailjs-com';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Visioncomp.css';
import './Process.css';


import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import greenarrow from '../assets/others/Asset 18@2x.png'
import redarrow from '../assets/others/Asset 17@2x.png'

import img3 from '../assets/home/Asset 11@2x.jpg'
import img4 from '../assets/home/Asset 20@2x.jpg'
import img5 from '../assets/home/Asset 21@2x.jpg'



function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <img alt="image" className={className} style={{ display: "inline",width:"50px",height:"50px"}} src={greenarrow} onClick={onClick} width="100"/>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;

    return (
          <img alt="image" className={className} style={{display: "inline",width:"50px",height:"50px",zIndex:"1"}} src={redarrow} onClick={onClick} width="100"/>
    );
  }

  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_1rkhitr', 'template_phwco5c', e.target, 'user_VuJMdG9CfHcpQBNs13hfC')
      .then((result) => {
          alert(result.text);
      }, (error) => {
          alert(error.text);
      });
      e.target.reset();

  }
  
class HomeComponent extends Component {
    static defaultProps = {
        center: {
          lat: 26.162929,
          lng: 72.929814,
        },
        zoom: 11
      };
      

    render(){

        const settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:true,
            autoplaySpeed:4000,
            nextArrow:<SampleNextArrow/>,
            prevArrow:<SamplePrevArrow/>
          };

        return(

            <>
                    {/* This is 1st tile */}
                    <Row style={{padding:"0%",marginTop:"5%"}}> 
                                            <div style={
                                            { 
                                                backgroundImage:`url('/images/home.jpg')` ,backgroundSize: "cover",width:'100%',height:'92vh',
                                                alignItems: "center",
                                                justifyContent: "center",
                                                display:"flex",
                                                flexDirection:"column",
                                                paddingTop:"5%",
                                                paddingBottom:"5%",
                                            }}>
                                            
                                            <div style={{marginTop:"2%",position:"relative"}}>
                                            <h1 style={{fontFamily:'CrimsonText-SemiBold',lineHeight:"107%",color:'whitesmoke',fontSize:"40pt",textAlign:"center"}}>Step into the world of</h1> 
                                            <h1 style={{fontFamily:'CrimsonText-SemiBold',lineHeight:"107%",color:'whitesmoke',fontSize:"40pt",textAlign:"center"}}>effortless gourmet</h1> 

                                            </div>
                                            <div style={{paddingTop:"0%",position:"static",marginTop:"1%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                                            <img alt="image" src={dots} width="4%"/> 
                                            </div>
                                            
                                            
                                            <div style={{fontFamily:'Montserrat-Medium',color:'whitesmoke',fontSize:"12pt",marginTop:"2%",paddingLeft:"32%",paddingRight:"32%"}}>
                                            <p style={{textAlign:"left"}}>
                                            TGS Foodz brings together taste and convenience in a series of high quality, finely crafted food products that capture
                                             the authentic taste of India. Our products are made at our ultra- modern plant in Jodhpur, 
                                            Rajasthan from the finest natural ingredients chosen by our farm experts under the most  hygienic conditions
                                            </p>     

                                                 
                                            </div>

                                            </div>
                                        </Row>       

                                {/*Yellow card Quality control starts here*/}

                                <div className="column-yellow" style={{padding:"5% 20%",color:"black"}}>
                                    <p style={{fontFamily:"CrimsonText-Regular",fontSize:"180%",padding:"1% 0%",lineHeight:"115%"}}>
                                    TGS Foodz is your perfect partner in the food industry; our flavours are innovative and our products 
                                    meet the most stringent quality norms.  Our impeccable quality and innovative product range 
                                    has propelled  us to become the fastest growing exporter of Indian ready-to-eat food  products today.
                                    </p>
                                    <hr style={{height:"1%",color:"black",backgroundColor:"black"}}></hr>
                                    
                                    <p style={{fontFamily:"Montserrat-Medium",fontSize:"120%",paddingBottom:"2%",paddingTop:"1%"}}>
                                    Join us on a culinary journey that aims to bring the taste of India to the world! 
                                    </p>
                                    <HashLink to='#Contact'>

                                    <Button style={{border:"0",backgroundColor:"black",borderRadius:"0",color:"#ffcd00",fontFamily:"Montserrat-Medium",fontSize:"115%",outline:"none",boxShadow:"none"}}>Join us</Button>
                                    </HashLink>

                                </div>



                                 {/* This is 3rd tile */}
                                 <Row style={{padding:"0%"}}> 
                                            <div style={
                                            { 
                                                backgroundImage:`url('/images/home1.jpg')` ,
                                                backgroundSize: "contain",backgroundRepeat:"no-repeat",
                                                width:'100%',height:'80vh',
                                                alignItems: "center",
                                                justifyContent: "center",
                                                display:"flex",
                                                flexDirection:"column",
                                            }}>
                                            
                                            <div style={{width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                                            <img alt="image" src={dots} width="4%"/> 
                                            </div>
                                            <div style={{marginTop:"2%",padding:"0% 5%"}}>
                                            <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"42pt",textAlign:"center"}}>
                                            Explore, Order, Eat, Repeat.
                                            </h1> 
                                            </div>
                                            <Link to='/Products/' style={{ marginTop:"3%"}}>
                                            <Button  style={
                                                {
                                                    border:"0",
                                                   borderRadius:"10px",backgroundColor:"black",
                                                    fontFamily:"Montserrat-Medium",fontSize:"130%",
                                                    boxShadow:"none"
                                                }
                                                    }>
                                                        View all our products
                                            </Button>
                                            </Link>

                                            
                                           

                                            </div>
                                        </Row>      

                            {/* This is white card tiles */}

                            <div style={{padding:"3% 10%"}}>
                                <Row style={{marginBottom:"3%"}}>
                                    <Col lg="6"  className="nopadding">
                                        <div style={{height:"100%"}}>
                                            <img alt="image" width="100%" src={img3}/>
                                        </div>
                                    </Col>
                                    <Col lg="6"  className="nopadding">
                                    <div className="column-red" style={{padding:"20%",color:"white",height:"100%",lineHeight:"115%",fontFamily:"CrimsonText-SemiBold",fontSize:"300%"}}>
                                        <p>A Food Journey to Share</p>
                                        <Link to='/Story/'>
                                            <Button  style={
                                                {
                                                    border:"2px solid white",
                                                    borderRadius:"10px",
                                                    backgroundColor:"#ff2e34",
                                                    fontFamily:"Montserrat-Medium",fontSize:"35%",
                                                    boxShadow:"none",
                                                    marginTop:"10%"
                                                }
                                                    }>
                                                    Know our story
                                            </Button>
                                            </Link>
                                    </div>
                                   
                                    </Col>
                                </Row>

                                <Row style={{marginBottom:"3%"}}>
                                 <Col lg="6"  className="nopadding">
                                    <div className="column-yellow" style={{padding:"20% 35% 20% 20%",lineHeight:"115%",color:"black",height:"100%",fontFamily:"CrimsonText-SemiBold",fontSize:"300%"}}>
                                        <p>Get, Set </p>
                                        <p style={{lineHeight:"70%"}}>and Process</p>

                                        <Link to='/Process/' >
                                            <Button  style={
                                                {
                                                    border:"2px solid black",
                                                    borderRadius:"10px",
                                                    backgroundColor:"#ffcd00",
                                                    fontFamily:"Montserrat-Medium",fontSize:"35%",
                                                    boxShadow:"none",
                                                    color:"black",
                                                    marginTop:"20%"
                                                }
                                                    }>
                                                    Learn more
                                            </Button>
                                            </Link>
                                    </div>
                                   
                                    </Col>
                                    <Col lg="6"  className="nopadding">
                                        <div style={{height:"100%"}}>
                                            <img alt="image" width="100%" src={img4}/>
                                        </div>
                                    </Col>
                                    
                                </Row>


                                <Row style={{marginBottom:"3%"}}>
                                    <Col lg="6"  className="nopadding">
                                        <div style={{height:"100%"}}>
                                            <img alt="image" width="100%" src={img5}/>
                                        </div>
                                    </Col>
                                    <Col lg="6"  className="nopadding">
                                    <div className="column-green" style={{padding:"17% 15% 17% 15%",color:"white",height:"100%",fontFamily:"CrimsonText-SemiBold",fontSize:"300%"}}>
                                        <p >Secrets from</p>
                                        <p style={{lineHeight:"50%"}}>the TGS kitchen</p>                      
                                        <Link to='/Recipes/' >
                                            <Button  style={
                                                {
                                                    border:"2px solid white",
                                                    borderRadius:"10px",
                                                    backgroundColor:"#00aa53",
                                                    fontFamily:"Montserrat-Medium",fontSize:"35%",
                                                    boxShadow:"none",
                                                    marginTop:"15%"
                                                }
                                                    }>
                                            Know our recipes
                                            </Button>
                                            </Link>
                                    </div>
                                   
                                    </Col>
                                </Row>

                            </div>


                {/*Carousel Starts here*/}
                <div style={{backgroundColor:"black",padding:"5% 20%",marginTop:"0%",textAlign:"center"}}>
                    <img alt="image" src={dots} width="7%" />
                    <h1 style={{fontFamily:"CrimsonText-Semibold",color:"white",textAlign:"center",fontSize:"250%",paddingBottom:"5%",paddingTop:"2%"}}>Words that matter to us</h1>
                    
                        <Slider {...settings}  style={{backgroundColor:"#ffcd00",padding:"5% 9.5%",textAlign:"left"}}>
                            <div>
                                
                                
                                <p style={{fontFamily:"CrimsonText-Italic",marginTop:"5%",paddingLeft:"10%",lineHeight:"125%",paddingRight:"10%",textAlign:"center",fontSize:"200%"}}>
                                Very impressed. Authentic cooking bases which can be used to whip up different dishes quickly. 
                                I particularly recommend the Korma Simmer Sauce … used it to prepare mutton korma which was just sumptuous.
                                </p>
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"7%",paddingLeft:"10%",paddingRight:"10%",textAlign:"center"}}>
                                - Indu Pareek
                                </p>

                            </div>
                            <div>
                                
                                
                                <p style={{fontFamily:"CrimsonText-Italic",marginTop:"5%",paddingLeft:"10%",lineHeight:"125%",paddingRight:"10%",textAlign:"center",fontSize:"200%"}}>
                                Have been importing food items from TGS Foodz for quite some time now. They customize their standard 
                                production as per my needs and have become my go-to resource for all vegetarian food items.
                                </p>
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"7%",paddingLeft:"10%",paddingRight:"10%",textAlign:"center"}}>
                                – Kratidis Vasilis
                                </p>

                            </div>
                               
                            <div>
                                
                                
                                <p style={{fontFamily:"CrimsonText-Italic",marginTop:"5%",paddingLeft:"10%",lineHeight:"125%",paddingRight:"10%",textAlign:"center",fontSize:"200%"}}>
                                They do their best to make premium products in the ready meal industry and provide consumers with genuinely trustworthy and high-quality products through automation.
                                The products have been successfully launched in the big chains in USA. 
                                </p>
                                <p style={{fontFamily:"Montserrat-Medium",paddingLeft:"10%",paddingTop:"5%",paddingRight:"10%",textAlign:"center"}}>
                                – Amir Khoshkabari 
                                    (Michka Foods LLC)
                                </p>

                            </div>
                           
                        
                        </Slider>

                        
                </div>

                <a id="Contact"></a>
                <Row id="Contacticon" style={{padding:"0%",marginTop:"0%"}} > 
                        <div style={
                        { 
                            
                            backgroundColor:"#E3E3E3",backgroundSize: "cover",width:'100%',
                            alignItems: "center",
                            justifyContent: "center",
                            display:"flex",
                            flexDirection:"column", 
                        }}>
                        
                        <div style={{paddingTop:"2%",position:"static",marginTop:"1%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                        <img alt="image" src={dots} width="4%"/> 
                        </div>
                        
                        <div style={{marginTop:"2%",position:"relative"}}>
                        <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'black',fontSize:"40pt"}}>
                            Get in touch</h1>           
                        </div>

                        <Row style={{padding:"0% 10%",marginTop:"3%"}}>
                            <Col lg="8">
                            <Form onSubmit={sendEmail}>
                            <Form.Row>
                                <Col>
                                <Form.Control style={{textAlign:"center"}} name="name" placeholder="Your name" />
                                </Col>
                                <Col>
                                <Form.Control style={{textAlign:"center"}} name="phone" type="text" placeholder="Phone" />
                                </Col>
                                <Col>
                                <Form.Control style={{textAlign:"center"}} name="reply_to" type="email" placeholder="Email" />
                                </Col>
                            </Form.Row>
                             <Form.Row style={{padding:"1%"}}>
                                 <Form.Control as="textarea"  name="message" style={{textAlign:"center",padding:"7% 7%"}} placeholder="Your message"/>
                                </Form.Row>
                                <Button style={{backgroundColor:"black",padding:"1% 10%",border:"0",outline:"none",boxShadow:"none"}} type="submit">
                                    Submit
                                </Button>
                            </Form>
                            </Col>
                            
                            <Col lg="4" style={{padding:"0% 5%",fontSize:"130%"}}> 
                            <p>
                                F-172, Agro Food Park Boranada Industrial Area Jodhpur, Rajasthan India- 342012
                            </p>
                            <b>Email</b>
                            <br></br>
                            info@tgsfoodz.com 
                            rupesh@tgsfoodz.com
                            <br></br>
                            <br></br>

                            <b>Phone</b>
                            <br></br>
                            +91 98290 21807 <br>
                            </br>
                            +91 94141 31113
                            </Col>
                        </Row>
                        

                        </div>
                    </Row>   
                    <div style={{backgroundColor:"#E3E3E3",padding:"5% 10%"}}> 
                        <div style={{width:"100%",height:"50vh"}}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d114593.17952351598!2d73.06988967864469!3d26.162929000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf521fb154e911a65!2sTGS%20Foodz%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1610273412075!5m2!1sen!2sin" width="100%" height="100%" frameborder="0" style={{border:'0'}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>

            </>
        )
    }
}
export default HomeComponent;
