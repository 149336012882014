import React, { Component } from 'react';

class Notfound extends Component {


    render(){
        return(

            <div style={{width:"100%",height:"50vh",textAlign:"center",marginTop:"50vh"}}>
            <h1 style={{fontSize:"400%",display:"inline",color:"#ff2e34"}}>4</h1>
            <h1 style={{fontSize:"400%",display:"inline",color:"#00aa53"}}>0</h1>
            <h1 style={{fontSize:"400%",display:"inline",color:"#ffcd00"}}>4</h1>
            <h1>NOT FOUND :(</h1>

            </div>
        )
    }
}
export default Notfound;