import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import redarrow from '../assets/others/Asset 17@2x.png'
import greenarrow from '../assets/others/Asset 18@2x.png'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <img alt="image" className={className} style={{ display: "inline",width:"50px",height:"50px"}} src={greenarrow} onClick={onClick} width="100"/>

    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;

    return (
          <img alt="image" className={className} style={{display: "inline",width:"50px",height:"50px"}} src={redarrow} onClick={onClick} width="100"/>
    );
  }

  function SpecialSlide(){
    return(
        
        <div style={{height:"100%",margin:"0%",display:"table"}}>
            <div  style={{display:"table-row"}}>
                        <div style={{borderRight:"20px solid black",width:"50%",paddingRight:"4%",height:"100%",display:"table-cell"}}>
                                <h1  style={{textAlign:"center",fontFamily:"Crimsontext-Regular",fontSize:"250%",marginTop:"10%"}}>
                                    Communications</h1>
                                
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"2%",padding:"30% 23%"}}>
                                We may use your Personal Information to contact you with newsletters; marketing 
                                or promotional materials and other information.
                                </p>
                        </div>
                        
                        <div style={{borderLeft:"20px solid black",width:"50%",paddingLeft:"4%",height:"100%",display:"table-cell"}}>
                                <h1  style={{textAlign:"center",fontFamily:"Crimsontext-Regular",fontSize:"250%",marginTop:"10%"}}>Security</h1>
                            
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"2%",padding:"10% 15%"}}>
                                The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, 
                                or method of electronic storage is 100% secure. While we strive to use commercially 
                                acceptable means to protect your Personal information, we cannot guarantee its absolute security due to the nature of the process. 
                                </p>
                        </div>
            </div>

        </div>
 
    );
  }

class PolicyComponent extends Component {

    constructor(props) {
        super(props);
       
    }
    render(){
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow:<SampleNextArrow/>,
            prevArrow:<SamplePrevArrow/>
          };
    
        return(

            <>
                    <Row style={{padding:"0%",marginTop:"5%"}}> 
                        <div style={
                        { 
                            
                            backgroundColor:"#E3E3E3",backgroundSize: "cover",width:'100%',height:'92vh',
                            alignItems: "center",
                            justifyContent: "center",
                            display:"flex",
                            flexDirection:"column",
                            paddingTop:"20%",
                            paddingBottom:"20%",
                        }}>
                        
                        <div style={{paddingTop:"2%",position:"static",marginTop:"1%",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                        <img alt="image" src={dots} width="4%"/> 
                        </div>
                        
                        <div style={{marginTop:"2%",position:"relative"}}>
                        <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'black',fontSize:"40pt"}}>
                            Cookies & Privacy Policy</h1>           
                        </div>
                        
                        <div style={{fontFamily:'Montserrat-Medium',color:'black',fontSize:"12pt",marginTop:"2%",marginBottom:"0%",paddingLeft:"26%",paddingRight:"26%"}}>
                        <p style={{textAlign:"left"}}>
                        Cookies are files with small amount of data, which may include an anonymous unique identifier. 
                        Cookies are sent to your browser from a website and stored on your computers hard drive.
                        </p> 
                        <p style={{textAlign:"left"}}>
                        Like many websites, we use “cookies” to collect information.
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                        However, if you do not accept cookies, you may not be able to use some portions of our Site.
                        </p> 

                        <p style={{textAlign:"left"}}>
                         This page informs you of our policies regarding the collection, 
                         use and disclosure of Personal Information we receive from users of the Site. 
                        </p>     


                        <p style={{textAlign:"left"}}>
                        We use your Personal Information only for providing and improving the Site. By using our Site, tgsfoodz.com, 
                        you agree to the collection and use of information in accordance with this policy.  
                        </p>                   
                        </div>
                      


                        </div>
                    </Row>   
                  

                  {/*Carousel Starts here*/}
                  <div style={{backgroundColor:"black",padding:"5% 15%"}}>
                        <Slider {...settings}  style={{backgroundColor:"#ffcd00",padding:"0% 5%",textAlign:"left"}}>
                           
                            <div  >
                                
                                <h1 style={{textAlign:"center",fontFamily:"Crimsontext-Regular",fontSize:"300%",marginTop:"5%"}}>Information Collection & Use</h1>
                                <br></br>
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"5%",paddingLeft:"10%",paddingRight:"10%"}}>
                                While using our Site we may ask you to provide us with certain personally identifiable information which
                                 can be used to contact or identify you.
                                </p>
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"2.5%",paddingLeft:"10%",paddingRight:"10%"}}>
                                 Personally identifiable information may include, but is not limited to: your name;
                                 Personal Information; Log Data. Like many site operators, we collect information that your browser sends whenever you visit our Site. 
                                </p>

                            </div>
                            <div  >
    
                                <h1 style={{textAlign:"center",fontFamily:"Crimsontext-Regular",fontSize:"300%",marginTop:"5%"}}>Log Data</h1>

                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"5%",paddingLeft:"5%",paddingRight:"5%"}}>
                                This Log Data may include information such as your computers Internet Protocol & IP address; browser type;
                                 browser version; the pages of 
                                our Site that you visit; the time and date of your visit; the time spent on those pages and other statistics. 
                                </p>

                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"2%",paddingLeft:"5%",paddingRight:"5%"}}>
                                In addition we may use third party services such as Google Analytics that collect, monitor and analyze this 
                                and other associated data.                               
                                </p>

                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"2%",paddingLeft:"5%",paddingRight:"5%"}}>
                                The Log Data section is for businesses that use analytics or tracking services in websites or apps,
                                 like Google Analytics.                          
                                </p>
                            </div>
                                <SpecialSlide/>
                               

                            <div >
                                
                                <h1  style={{textAlign:"center",fontFamily:"Crimsontext-Regular",fontSize:"300%",marginTop:"5%"}}>Changes To This Privacy Policy</h1>
                                
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"5%",padding:"0% 4%"}}>
                                This Privacy Policy is effective as of March 31st, 2016 and will remain in effect except with
                                 respect to any changes in its provisions
                                 in the future, which will be in effect immediately after being posted on this page.  
                                </p>
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"2%",padding:"0% 4%"}}>
                                We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. 
                                Your continued use of the Service after we post any modifications to the Privacy Policy 
                                on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by
                                 the modified Privacy Policy.
                               
                                </p>
                                <p style={{fontFamily:"Montserrat-Medium",marginTop:"2%",padding:"0% 4%"}}>
                                If we make any material changes to this Privacy Policy, we will notify you either through 
                                the email address you have provided us, or by placing a prominent notice on our Site.                                </p>

                            </div>
                        </Slider>
                        </div>
                </>
        )
    }
}
export default PolicyComponent;