import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router,Switch,Route, Link } from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import HeaderComponent from './Components/HeaderComponent';
import FooterComponent from './Components/FooterComponent';
import FaqComponent from './Components/FaqComponent';
import TermsComponent from './Components/TermsComponent';
import PolicyComponent from './Components/PolicyComponent';
import ScrollToTop from './Components/ScrollToTopComponent';
import StoryComponent from './Components/StoryComponent';
import PrivatelabelComponent from './Components/PrivatelabelComponent';
import RecipesComponent from './Components/RecipesComponent';
import ProcessComponent from './Components/ProcessComponent';
import ProjectComponent from './Components/ProjectComponent';
import HomeComponent from './Components/HomeComponent';
import Notfound from './Components/Notfound';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const productAdjust=({match})=>(
  <ProjectComponent cond='false' id={match.params.id}/>
  )
const productMacfix=({match})=>(
  <ProjectComponent cond='true' id="1"/>
)

function App(){
         
        const {height,width}=useWindowDimensions();
        return (height<width)?
            <div className="App" >
              <Router> {/* This is here due to workaround of anchor tags in safari */}

                <div style={{position:'fixed',zIndex:'9999',bottom:"15%",left:"94%"}}>
                        <HashLink to='/#Contact'>
                          <img src="/images/Contact.png" title="Contact Us" height="50%" width="55%" />
                        </HashLink>
                      </div>
                      <div style={{position:'fixed',zIndex:'9999',bottom:"5%",left:"93.5%"}}>
                        <a href="https://drive.google.com/uc?export=download&id=18LtyLq0v04glY0ev6dhm2w8AeHzPj_Y9" download>
                        <img src="/images/brochure.png" title="Download Brochure" height="50%" width="65%" />
                      </a>
                    </div>
                  <ScrollToTop>
                    <HeaderComponent />
                    
                    <Switch>
                      
                      <Route exact path = '/' component={HomeComponent}/>
                      <Route path = '/Products/:id/' component={productAdjust} />
                      <Route path = '/Products/' component={productMacfix}/>
                      <Route path = '/FAQ/' component={FaqComponent}/>
                      <Route path = '/Terms/' component={TermsComponent}/>
                      <Route path = '/Policy/' component={PolicyComponent}/>
                      <Route path = '/Story/' component={StoryComponent}/>
                      <Route path = '/Process/' component={ProcessComponent}/>
                      <Route path = '/Recipes/' component={RecipesComponent}/>
                      <Route path = '/Privatelabel/' component={PrivatelabelComponent}/>
                      <Route component={Notfound} />

                    </Switch>
                    <FooterComponent />
                    </ScrollToTop>
                </Router>
            </div>:
            <div className="App" >
            <div style={
                { 
                    backgroundImage:`url('/images/Mobile01.jpg')` ,backgroundSize: "cover",
                    backgroundPosition:"center",
                    width:'100%',height:'100vh',
                    zIndex:'10000'
                }}>
            </div>
            </div>
      
          

}

export default App;