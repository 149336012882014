import React, { Component } from 'react';
import { Row, Col} from 'react-bootstrap';
import  {Link} from 'react-router-dom';


class FooterComponent extends Component{
 
    render(){
        return(


                <Row style={{ alignContent: "center", backgroundColor: "black", padding: "2% 10%", color: "white" }}>
                    <Col xs="12" xl="2" xm="2" style={{ fontSize: "110%", fontFamily: "Montserrat-Bold", padding: "1% 0%", borderRight: "0px solid white" }}>
                        <div className="text-center">
                            <Link to="/FAQ" style={{ color: '#FFF' }}>FAQ</Link> 
                        </div>
                    </Col>
                    <Col xl="1">
                    <div style={{height:"100%", borderLeft: "2px solid white"}}>

                    </div>
                    </Col>
                    <Col xs="12" xl="8" xm="10" style={{color:"#8c8c8c",fontSize:"110%",textAlign:"center",display:"inline-block",padding:"1% 0%"}}>
                        © 2020 TGS Foodz Pvt. Ltd. All Rights Reserved.&nbsp;
                        <span style={{ color:"white",fontSize: "100%", fontFamily: "Montserrat-Bold" }}>
                        <Link to="/Terms" style={{ color: '#FFF' }}>Terms Of Use</Link> &nbsp;
                        </span>
                        and&nbsp;
                        <span style={{color:"white",fontSize: "100%", fontFamily: "Montserrat-Bold" }}>
                        <Link to="/Policy" style={{ color: '#FFF' }}>Privacy Policy</Link>
                        </span>

                    </Col>
                </Row>
     )
    }      
}

export default FooterComponent;
