import React, { Component } from 'react';
import { Row, Col, } from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css'

import img1 from '../assets/indiansweets/Asset 15@2x.png'
import img2 from '../assets/indiansweets/Asset 16@2x.png'
import img3 from '../assets/indiansweets/Asset 17@2x.png'
import img4 from '../assets/indiansweets/Asset 18@2x.png'

class IndiansweetsComponent extends Component {

    render(){

        return(

            <>
                <Row style={{padding:"6% 12%"}}>
                    <Col xs="12" sm="4" md="4" lg="4" style={{paddingTop:"6%",paddingBottom:"6%"}}>
                    <h1 style={{fontFamily:"CrimsonText-Regular",textAlign:"left",fontSize:"35pt"}}>Halwa</h1>
                    <br></br>

                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li>Badam Halwa </li>
                    <li>Doodhi Halwa </li>
                    <li>Gajar Halwa </li>
                    <li>Gond Pak Halwa </li>
                    <li>Moong Dal Halwa </li>
                    <li>Suji Halwa</li>
                    

                    </ul>
                    <br></br>
                    <br></br>

                    <h1 style={{fontFamily:"CrimsonText-Regular",textAlign:"left",fontsize:"35pt"}}>Others</h1>
                    <br></br>
                  

                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li> Gulab Jamun </li>
                    <li> Raj Bhog </li>
                    <li> Rasgulla </li>
                    
                    </ul>

                   

                    </Col>
                    <Col xs="12" sm="12" md="7" lg="8" className="nopadding">
                    <br></br>

                    <Row className="">
                        <Col className="nopadding">
                        <img alt="image" src={img1} width="100%" height="100%"/>
                        </Col>
                        <Col className="">
                        <img alt="image" src={img2} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"2%"}} className="">
                        <Col className="nopadding">
                        <img alt="image" src={img3} width="100%" height="100%"/>

                        </Col>
                        <Col className="">
                        <img alt="image" src={img4} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    
                    </Col>
                 
                </Row>

            </>
        )
    }
}
export default IndiansweetsComponent;