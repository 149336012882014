import React, { Component } from 'react';
import { Row, Col, Form ,Button} from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import yellowarrow from '../assets/others/Asset 12@2x.png'
import redarrow from '../assets/others/Asset 17@2x.png'

import greenarrow from '../assets/others/Asset 18@2x.png'
import img1 from '../assets/others/Asset 35@2x.png'

import emailjs from 'emailjs-com';

import one from '../assets/others/Asset 24@2x.png'
import two from '../assets/others/Asset 23@2x.png'
import three from '../assets/others/Asset 22@2x.png'
import four from '../assets/others/Asset 21@2x.png'
import five from '../assets/others/Asset 20@2x.png'


function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_1rkhitr', 'template_phwco5c', e.target, 'user_VuJMdG9CfHcpQBNs13hfC')
      .then((result) => {
          alert(result.text);
      }, (error) => {
          alert(error.text);
      });
      e.target.reset();

  }
  
function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <img alt="image" className={className} style={{ display: "inline",width:"50px",height:"50px"}} src={greenarrow} onClick={onClick} width="100"/>

    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;

    return (
          <img alt="image" className={className} style={{display: "inline",width:"50px",height:"50px"}} src={yellowarrow} onClick={onClick} width="100"/>
    );
  }
  
class PrivatelabelComponent extends Component {
    constructor(props) {
        super(props);
       
    }
    render(){
        const settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow:<SampleNextArrow/>,
            prevArrow:<SamplePrevArrow/>
          };
    
        return(

            <>
                    <Row style={{padding:"0%",marginTop:"5%"}}> 
                        <div style={
                        { 
                            
                            backgroundColor:"#E3E3E3",backgroundSize: "cover",width:'100%',height:'92vh',
                            alignItems: "center",
                            justifyContent: "center",
                            display:"flex",
                            flexDirection:"column",
                        }}>
                        
                        <div style={{paddingTop:"2%",position:"static",width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                        <img alt="image" src={dots} width="4%"/> 
                        </div>
                        
                        <div style={{marginTop:"2%",position:"relative"}}>
                        <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'black',fontSize:"40pt"}}>
                            Private Label Program</h1>           
                        </div>
                        
                        <div style={{fontFamily:'Montserrat-Medium',color:'black',fontSize:"12pt",marginTop:"2%",paddingLeft:"25%",paddingRight:"25%"}}>
                        <p style={{textAlign:"left"}}>
                        We do Private Label manufacturing for all our current range of products.
                        With our Private Label program you have complete control of the look and feel of the products.
                         Whether you need the products in glass jars, laminated pouches/mono-cartons, trays or bags, our 
                         in-house design specialists will completely customize the packaging for you. Besides customizing the 
                         packaging, we can also make changes to our standard products as per your specifications.
                         Often we’ve developed an entirely  new product range when needed by our clients.
                        </p>                   
                        </div>

                        </div>
                    </Row>   
                  

                  {/*Carousel Starts here*/}
                  <div style={{backgroundColor:"black",padding:"5% 20%"}}>
                      <h1 style={{fontFamily:"CrimsonText-Semibold",color:"white",textAlign:"center",paddingBottom:"5%",paddingTop:"1%"}}>How it Works </h1>
                      
                        <Slider {...settings}  style={{backgroundColor:"black",padding:"0% 5%",textAlign:"left"}}>
      
                            <div>
                                <img  alt="Private Label" src={img1} width="100%"/>
                            </div>
                            
                           
                        </Slider>
                    </div>
                    
                    <div style={{backgroundColor:"black",padding:"0% 15% 5%"}}>
                        <Row style={{fontFamily:"Montserrat-Medium",color:"white",marginBottom:"4%"}}>
                            <Col lg="2" style={{textAlign:"right",marginRight:"1%",paddingTop:"1%"}}>
                            <img  alt="image" src={one} width="40%" />
                            </Col>
                            <Col lg="8" >
                                <p style={{padding:"2% 0%"}}>
                                    We send across product samples (sauces, dips, marinades, cooking pastes, 
                                    ready to eat etc) to you for approval.
                                </p>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>

                        <Row style={{fontFamily:"Montserrat-Medium",color:"white",marginBottom:"4%"}}>
                            
                            <Col lg="2" style={{textAlign:"right",marginRight:"1%",marginTop:"0.75%",paddingBottom:"2%"}}>
                            <img  alt="image" src={two} width="40%" />
                            </Col>  
                            
                            <Col lg="8">
                                <p style={{padding:"2% 0%"}}>
                                Once the samples are approved we start with the private labeling process.
                                </p>
                            </Col>
                        
                        </Row>
                        
                        <Row style={{fontFamily:"Montserrat-Medium",color:"white",marginBottom:"4%"}}>
                            
                        <Col lg="2" style={{textAlign:"right",marginRight:"1%",paddingTop:"2%",paddingBottom:"2%"}}>
                            <img alt="image" src={three} width="40%" />
                            </Col>  
                            <Col lg="8">
                                <p style={{padding:"2% 0%"}}>
                                If you already have your own brand, we create product specific labels using  your brand, put these on the products and send these across again for approval.
                                </p>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                        
                        
                        <Row style={{fontFamily:"Montserrat-Medium",color:"white",marginBottom:"5%"}}>
                            
                        <Col lg="2" style={{textAlign:"right",marginRight:"1%",paddingBottom:"6%",paddingTop:"6%"}}>
                            <img alt="image" src={four} width="40%" />
                            </Col>                              <Col lg="8">
                                <p style={{padding:"2% 0%"}}>
                                    If you do not have a brand yet, our Graphic Design Specialists will create 2-3 
                                    options for labels ensuring full regulatory compliance (including ingredients, allergens,
                                    contact information etc) and send these across for approval. Once approved, we put the labels
                                    on the actual products and send these across for you to have a feel of what the product will 
                                    look like when it hits the shelves.    
                                </p>
                            </Col>
                            <Col lg="2" >
                            </Col>
                        
                        </Row>
                        
                        <Row style={{fontFamily:"Montserrat-Medium",color:"white",marginBottom:"0%"}}>
                                
                        <Col lg="2" style={{textAlign:"right",marginRight:"1%",paddingBottom:"2%",paddingTop:"2%"}}>
                            <img  alt="image" src={five} width="40%" />
                            </Col>  
                            <Col lg="8" >
                                <p style={{padding:"2% 0%"}}>
                                    We manufacture the products, design and print the labels,
                                    produce the final product and ship it directly to you. Your product with your own custom branding is ready!
                                </p>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                        
                    </div>


                    {/* This is the contact form */}
                    
                    <div style={
                        { 
                            
                            backgroundColor:"#E3E3E3",backgroundSize: "cover",width:'100%',height:'60vh',
                            alignItems: "center",
                            justifyContent: "center",
                            display:"flex",
                            flexDirection:"column",
                        }}>
                            <Form onSubmit={sendEmail}>
                            <Form.Row>
                                <Col>
                                <Form.Control style={{textAlign:"center"}} name="name" placeholder="Your name" />
                                </Col>
                                <Col>
                                <Form.Control style={{textAlign:"center"}} name="phone" type="text" placeholder="Phone" />
                                </Col>
                                <Col>
                                <Form.Control style={{textAlign:"center"}} name="reply_to" type="email" placeholder="Email" />
                                </Col>
                            </Form.Row>
                             <Form.Row style={{padding:"1%"}}>
                                 <Form.Control as="textarea"  name="message" style={{textAlign:"center",padding:"10% 10%"}} placeholder="Your message"/>
                                </Form.Row>
                                <Button style={{backgroundColor:"black",padding:"1% 10%",border:"0"}} type="submit">
                                    Submit
                                </Button>
                            </Form>
                    </div>



                </>
        )
    }
}
export default PrivatelabelComponent;