import React, { Component } from 'react';
import { Row, Col, Card ,Button} from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import { HashLink} from 'react-router-hash-link';

import './Visioncomp.css'
import './Sauce.css'
import img1 from '../assets/condiments/Asset 21@2x.png'
import img2 from '../assets/condiments/Asset 22@2x.png'
import img3 from '../assets/condiments/Asset 23e@2x.png'
import img4 from '../assets/condiments/Asset 24e@2x.png'
import img5 from '../assets/condiments/Asset 32@2x.png'
import img6 from '../assets/condiments/Asset 33@2x.png'
import img7 from '../assets/condiments/Asset 34@2x.png'
import img8 from '../assets/condiments/Asset 35@2x.png'

import {cond} from './data/condiments_details';

class CondimentComponent extends Component {

    render(){

        return(

            <>
                {/* This is 1st tile */}
                
                <Row style={{backgroundColor:"black",padding:"2% 35%",alignItems:"center"}}>
                    <Col xs="12" lg="5"  className="column-space text-right zoom ">
                    <HashLink to='#Dips'>
                        <Button  id="1" value="Simmer Sauces" className="mont-med text-center column-yellow">
                            Dips
                        </Button>
                    </HashLink>
                    </Col>
                    
                    <Col xs="12"  lg="5"   className="column-space text-left zoom ">
                    <HashLink to='#Pastes'>
                        <Button  id="2" value="Hot Sauces" className="mont-med text-center column-green">
                            Pastes
                        </Button>
                    </HashLink>
                    </Col>
                </Row>

            <a id="Dips" ></a>
            <Card  className="column-yellow" style={{padding:"5% 21%",alignItems:"center"}}>
                <Card.Body>
                    <Card.Title style={{textAlign:"center",fontSize:"250%",fontFamily:"CrimsonText-SemiBold"}}>
                        Dips
                    </Card.Title>
                    <Card.Text style={{marginTop:"5%",justifyContent:"center",textAlign:"left",fontFamily:"Montserrat-Medium"}}>
                    {cond['Dips']}
                    </Card.Text>
                </Card.Body>
            </Card>

                {/* This is 2nd tile */}


                <Row style={{padding:"6% 12%"}}>
                    <Col xs="12" sm="4" md="4" lg="4" style={{paddingTop:"9%",paddingBottom:"9%"}}>
                    <h1 style={{fontFamily:"CrimsonText-Regular"}}>Varieties</h1>
                    <br></br>
                    <br></br>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Indian</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li> Chatpata Tomato Dip </li>
                    <li> Sweet Chili Garlic Dip </li>
                    <li> Sweet Mango Chutney </li>
                    <li> Coriander Mint Chutney </li>
                    <li> Samosa Pakora Chutney </li>
                    <li> Date and Tamarind Chutney </li>
                    <li> Spicy Green Chutney</li> 

                    </ul>

                    <br></br>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>International</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li>Cheese Jalapeno Dip </li>
                    <li>Hummus (Classic/ Cheesy/Smoky/Spicy) </li>
                    <li>Moroccan Harissa Dip </li>
                    <li>Taco Dip </li>
                    <li>Spicy Salsa Dip </li>
                    <li>Tartare Dip </li>
                  
                    </ul>

                   

                    </Col>
                    <Col xs="12" sm="12" md="7" lg="8" className="nopadding">
                    <br></br>

                    <Row className="">
                        <Col className="nopadding">
                        <img alt="image" src={img1} width="100%" height="100%"/>
                        </Col>
                        <Col className="">
                        <img alt="image" src={img2} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"2%"}} className="">
                        <Col className="nopadding">
                        <img alt="image" src={img3} width="100%" height="100%"/>

                        </Col>
                        <Col className="">
                        <img alt="image" src={img4} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    
                    </Col>
                   
                </Row>
               
              

                {/* This is 3rd tile */}
                <a id="Pastes" ></a>

                <Card  className="column-green" style={{padding:"5% 22%",alignItems:"center",color:"white"}}>
                <Card.Body>
                    <Card.Title style={{textAlign:"center",fontSize:"250%",fontFamily:"CrimsonText-SemiBold"}}>
                        Pastes
                    </Card.Title>
                    <Card.Text style={{marginTop:"5%",justifyContent:"center",textAlign:"left",fontFamily:"Montserrat-Medium"}}>
                    {cond['Pastes']}
                    </Card.Text>
                </Card.Body>
                </Card>

                {/*This is the 4th tile*/}
                
                <Row style={{padding:"6% 12%"}}>
                    <Col xs="12" sm="4" md="4" lg="4" style={{paddingTop:"9%",paddingBottom:"9%"}}>
                    <h1 style={{fontFamily:"CrimsonText-Regular"}}>Varieties</h1>
                    <br></br>
                    <br></br>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Indian</h2>
                    <br></br>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                   
                    <li> Boiled Onion Paste </li>
                    <li> Garlic Paste </li>
                    <li> Ginger & Garlic Paste </li>
                    <li> Ginger Paste </li>
                    <li> Red Chilli Paste </li>
                    <li> Red Paste </li>
                    <li> Sautéed Onion Paste </li>
                    <li> Sautéed Onion with </li>
                    Ginger Garlic Paste 
                    <li> Sautéed Onion with</li>
                    Tomato & Ginger Paste

                    </ul>

            
                    </Col>
                    <Col xs="12" sm="12" md="7" lg="8" className="nopadding">
                    <br></br>

                    <Row className="">
                        <Col className="nopadding">
                        <img alt="image" src={img5} width="100%" height="100%"/>
                        </Col>
                        <Col className="">
                        <img alt="image" src={img6} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"2%"}} className="">
                        <Col className="nopadding">
                        <img alt="image" src={img7} width="100%" height="100%"/>

                        </Col>
                        <Col className="">
                        <img alt="image" src={img8} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    
                    </Col>
                   
                </Row>
               
                
              
                
                {/* This div containes the 6th tile */}
                <div style={{padding:"5%",backgroundColor:"black",alignItems:"center"}}>
                    <div style={
                    { 
                        alignItems: "center",
                        justifyContent: "center",
                        display:"flex",
                        wordWrap:"break-word",
                        flexDirection:"column",
                        width:"100%"
                    }}>

                    <div style={{width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                    <img alt="image" src={dots} width="5%"/> 
                    </div>
                    
                    <div style={{marginTop:"1%"}}>
                    <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"270%"}}>Packaging Sizes</h1>           
                    </div>
                    </div>
                    <Row style={{backgroundColor:"black",alignItems:"center",marginTop:"2%",paddingLeft:"15%",paddingRight:"15%"}}>
                    <Col sm style={{paddingRight:"0%",paddingLeft:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#ff2e34",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                375 g
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Glass Bottle
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>


                    <Col sm style={{margin:"1%",paddingLeft:"0%",paddingRight:"0%",paddingTop:"2%",paddingBottom:"2%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#00aa53",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                1 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col sm style={{paddingLeft:"0%",paddingRight:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#ffcd00",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                2.5 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card></Col>

                </Row>
                    
                </div>
            </>
        )
    }
}
export default CondimentComponent;