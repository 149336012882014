import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css'

import img1 from '../assets/readytoeat/Asset10@2x.png'
import img2 from '../assets/readytoeat/Asset12@2x.png'
import img3 from '../assets/readytoeat/Asset14@2x.png'
import img4 from '../assets/readytoeat/Asset16@2x.png'

class ReadyComponent extends Component {

    render(){

        return(

            <>
                <Row style={{padding:"6% 12%"}}>
                    <Col xs="12" sm="4" md="4" lg="4">
                    <h1 style={{fontFamily:"CrimsonText-Regular"}}>Varieties</h1>
                    <br></br>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li>Aloo Chole  </li>
                    <li>Aloo Mutter </li>
                    <li>Chana Masala </li>
                    <li>Dal Makhani </li>
                    <li>Dal Tadka </li>
                    <li>Hyderabadi Biryani </li>
                    <li>Kadhi Pakora </li>
                    <li>Kashmiri Dum Aloo </li>
                    <li>Konkan Veg Curry </li>
                    <li>Mumbai Style Pav Bhaji  </li>
                    <li>Mutter Paneer </li>
                    <li>Navratna Korma </li>
                    <li>Palak Paneer </li>
                    <li>Paneer Makhani </li>
                    <li>Paneer Butter Masala </li>
                    <li>Pindi Chhole  </li>
                    <li>Pulusu </li>
                    <li>Soya Baigan Keema </li>
                    <li>Rajma Masala </li>
                    <li>Sarson ka Saag </li>
                    <li>Veg Biryani </li>
                    <li>Veg Jalfraize </li>
                    <li>Veg Kadhai </li>
                    <li>Veg Pulao</li>
                    

                    </ul>

                   

                    </Col>
                    <Col xs="12" sm="12" md="7" lg="8" className="nopadding">
                    <br></br>

                    <Row className="">
                        <Col className="nopadding">
                        <img alt="image" src={img1} width="100%" height="100%"/>

                        </Col>
                        <Col className="">
                        <img alt="image" src={img2} width="100%" height="100%"/>

                        </Col>
                    </Row>
                    <Row style={{marginTop:"2%"}} className="">
                        <Col className="nopadding">
                        <img alt="image" src={img3} width="100%" height="100%"/>

                        </Col>
                        <Col className="">
                        <img alt="image" src={img4} width="100%" height="100%"/>

                        </Col>
                    </Row>
                    
                    </Col>
                    {/* <Col sm xs="6" sm="4" md="4" lg="4" style={{marginTop:"3%"}}>
                    <Row>
                    <img alt="image" src={img1} width="75%"/>
                    </Row>
                    <Row style={{marginTop:"1%"}}>
                    <img alt="image" src={img1} width="75%"/>
                    </Row>
                    </Col>
                    <Col sm xs="6" sm="4" md="4" lg="4" style={{marginTop:"3%"}}>
                    <Row>
                    <img alt="image" src={img2} width="75%"/>
                    </Row>
                    <Row style={{marginTop:"1%"}}>
                    <img alt="image" src={img2} width="75%"/>
                    </Row>        
                    </Col> */}
                </Row>

                                
                {/* This div containes the 3rd tile */}
                <div style={{padding:"5%",backgroundColor:"black",alignItems:"center"}}>
                    <div style={
                    { 
                        alignItems: "center",
                        justifyContent: "center",
                        display:"flex",
                        flexDirection:"column",
                        width:"100%"
                    }}>

                    <div style={{width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                    <img alt="image" src={dots} width="5%"/> 
                    </div>
                    
                    <div style={{marginTop:"1%"}}>
                    <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"270%"}}>Packaging Sizes</h1>           
                    </div>
                    
                    </div>
                    <Row style={{backgroundColor:"black",alignItems:"center",marginTop:"2%",paddingLeft:"15%",paddingRight:"15%"}}>
                    <Col sm style={{paddingRight:"0%",paddingLeft:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#ff2e34",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                300 g
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Mono Carton
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>


                    <Col sm style={{margin:"1%",paddingLeft:"0%",paddingRight:"0%",paddingTop:"2%",paddingBottom:"2%"}}>
                    <Card  className="card-mod" style={{backgroundColor:"#00aa53",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                1 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col sm style={{paddingLeft:"0%",paddingRight:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#ffcd00",alignItems:"center"}}>
                        <Card.Body style={{padding:"20%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"330%",marginBottom:"0%"}}>
                                2.5 kg
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"170%",textAlign:"center"}}>
                            Pouch
                            </Card.Text>
                        </Card.Body>
                    </Card></Col>

                </Row>
                    
                </div>
            </>
        )
    }
}
export default ReadyComponent;