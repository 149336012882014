import React, { Component } from 'react';
import { Container, Row, Col,  Button } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import './Visioncomp.css'

import ImageComponent from './ImageComponent';
import CurriesComponent from './CurriesComponent';
import MarinadesComponent from './MarinadesComponent';
import ReadyComponent from './ReadyComponent';
import VeganComponent from './VeganComponent';
import ComboComponent from './ComboComponent';
import CookingComponent from './CookingComponent';
import CondimentComponent from './CondimentComponent';
import IndiansweetsComponent from './IndiansweetsComponent';
import SnackComponent from './SnackComponent';



class VisionComponent extends Component {
   
    constructor(props){
       super(props);
 
       this.state={
           view:props.name     
        };
   }

   
   
    changeItem(value){
          
        this.setState({view:value});
       
        window.scroll({
            top:0,
            left:0,
            behavior:'smooth',
        })
    
      
    }

    render(){
        const buttonDet={
            1:["Curries","column-red"],
            2:["Ready to Eat",'column-red'],
            3:["Frozen Products",'column-red'],
            4:["Marinades",'column-green'],
            5:["Vegan",'column-green'],
            6:["Indian Sweets",'column-green'],
            7:["Condiments",'column-yellow'],
            8:["Cooking Sauces",'column-yellow'],
            9:["Combo Meals",'column-yellow'],
        }
        const positions={
            "Curries":[[7,4,8,2],[5,9,3,6]],
            "Ready to Eat":[[7,1,4,8],[5,9,3,6]],
            "Frozen Products":[[7,1,4,8],[2,5,9,6]],
            "Marinades":[[7,1,8,2],[5,9,3,6]],
            "Vegan":[[7,1,4,8],[2,9,3,6]],
            "Indian Sweets":[[7,1,4,8],[2,5,9,3]],
            "Condiments":[[1,4,8,2],[5,9,3,6]],
            "Cooking Sauces":[[7,1,4,2],[5,9,3,6]],
            "Combo Meals":[[7,1,4,8],[2,5,3,6]],    
        }
        const view1=positions[this.state.view][0];
        const view2=positions[this.state.view][1];

        const button1 = []
        const button2 = []

        view1.forEach(element => {
            button1.push( 
               <Col xs="12" lg="3" className="column-space text-center ">
                   <Link to={`/Products/${element}/`}>
                   <Button  id="1" value="Condiments"  onClick={(e)=>this.changeItem(buttonDet[element][0])} className={`mont-med text-center ${buttonDet[element][1]} zoom`}>
                       {buttonDet[element][0]}
                   </Button>
                   </Link>

               </Col>
               )
          })

        view2.forEach(element => {
            button2.push( 
               <Col xs="12" lg="3" className="column-space text-center ">
                   <Link to={`/Products/${element}/`}>
                   <Button  id="1"  onClick={(e)=>this.changeItem(buttonDet[element][0])} className={`mont-med text-center ${buttonDet[element][1]} zoom`}>
                       {buttonDet[element][0]}
                   </Button>
                   </Link>
               </Col>
               )
          })
          

        
        return(

            <Container fluid style={{padding:"0%"}}>
                {/* This row containes the 1st tile */}
               
                <ImageComponent name={this.state.view}/>
                
                {/* This row containes the 2nd tile
                    Switching must occur here
                */}
                {(() => {
                
                switch (this.state.view) {
                    case 'Curries':
                        return (
                            <CurriesComponent/>
                        )
                    case 'Ready to Eat':
                        return (
                            <ReadyComponent />
                        )
                    case 'Marinades':
                        return (
                            <MarinadesComponent/>
                        )
                    case 'Cooking Sauces':
                        return (
                            <CookingComponent/>
                        )
                    case 'Indian Sweets':
                        return (
                            <IndiansweetsComponent/>
                        )
                    case 'Vegan':
                        return (
                            <VeganComponent/>
                        )
                    case 'Combo Meals':
                        return (
                            <ComboComponent/>
                        )
                    case 'Condiments':
                        return (
                            <CondimentComponent/>
                        ) 
                    case 'Frozen Products':
                        return (
                            <SnackComponent/>
                        )   
                    default:
                        return (
                        <div>JSK</div>
                        )
                    }
                })()}


                {/* This div containes the Buttons tile it uses changeItem to set state and change color of buttons*/}

                <div style={{paddingLeft:"10%",paddingRight:"10%",paddingTop:"5%",alignItems:"center"}}>
                <h2 style={{fontFamily:"CrimsonText-Regular",fontSize:"220%"}}>Check our other product categories</h2>
               
                <Row style={{paddingTop:"1%",alignItems:"center"}}>
                    
                 {button1}
                
                </Row>

                
              
                <Row style={{paddingBottom:"5%",alignItems:"center"}}>
                    {button2}
                </Row>
                </div>
                {/* End of Buttons tile*/}

                
            </Container>
        )
    }
}
export default VisionComponent;