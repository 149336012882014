export const faqData = [
    ["0",
    "Is TGS Ready to Eat food safe to consume?",
    "TGS Ready to Eat food is prepared by the trained food experts and processed with time tested Retort technology. It is 100% safe to consume. The food is prepared as per the international certifications and standards like ISO 22000:2005, FDA, BRC, FSMS and FSSAI."],
   ["1",
    "What is Retort Technology?",
    "Retort processing of foods in rigid, semi-rigid and flexible packaging systems is the most reliable and acceptable form of food preservation for long duration. It’s a unique combination of packaging, processing and product technology. The cooked food is packed & sealed in special pouches. Once it is sealed, it is subjected to a special sterilization process by heating it to a designated temperature for a fixed duration. By this, the food becomes free of pathogens and as the pouch is sealed, no pathogen can develop for a long period of time till the sealing barrier is breached, preserving the quality & taste of the food."
    ],
    ["2",
    "What is the shelf life of TGS Ready to Eat food?",
    "TGS Ready to Eat food has sufficiently long shelf life. However it is recommended to use it within one year from the date of manufacturing."
    ],

    ["3",
        "What is nutrition value & ingredients of TGS Ready to Eat food?",
        "The nutritional value varies from product to product. The complete information is given on the individual packets."
    ]
    ,
    ["4",
        "Are there any preservatives added to TGS Ready to Eat food?",
        "No, there are no preservatives added to TGS Ready to Eat food. It is 100% natural."
    ]
    ,
    ["5",
        "Should I freeze TGS Ready to Eat food?",
        "TGS ready to eat food is fit for ambient storage; there is no need to freeze the pouches. However, any leftover food may be frozen."
    ]
    ,

    ["6",
        "Would I get the same taste as I get in home-made food?",
        "TGS food is prepared by experts who are known for creating mouth-watering food products. The packed food items retain the taste & quality for up-to an year."
    ],
    ["7",
        "Do I need to add any additives to TGS Ready to Eat food while pre-heating?",
        "No. TGS Ready to Eat food is pre-cooked ‘in the pouch’. However, you may add salt etc as per your taste."
    ],
    
    ["8",
       
        "What are the ways to pre-heat TGS Ready to Eat food?",
        "TGS ready to eat food is packed in two kinds of pouches- Microwaveable & Non Microwaveable. For Microwaveable Pouches, Make a small cut in the pouch, put it in the microwave and heat it before consumption. For Non Microwaveable & Microwaveable pouches, food can be heated in following ways- • Boil for 3-5 minutes – take the pouch and place it in boiling water. • Empty content in fry pan and heat it. • Empty content in a microwaveable utensil and heat it in a microwave oven." 
    ],
    ["9", 
    "How do I know that a particular packet is not fit for consumption?",
    "If the packing is damaged or the pouches are bloated, please do not consume the food as pathogens may have developed in that food."
    ]
];