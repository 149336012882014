import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import dots from '../assets/curries/2BannerImage/Asset 10@2x.png'
import './Visioncomp.css'

import img1 from '../assets/combomeals/Asset 10@2x.png'
import img2 from '../assets/combomeals/Asset 14@2x.png'
import img3 from '../assets/combomeals/Asset 15@2x.png'

import img4 from '../assets/combomeals/Asset 17@2x.png'
import img5 from '../assets/combomeals/Asset 18@2x - Copy.png'
import img6 from '../assets/combomeals/Asset 21@2x.png'


class ComboComponent extends Component {

    render(){

        return(

            <>
                <Row style={{padding:"6% 15%"}} >
                    <Col xs="12" sm="12" md="7" lg="6" >
                    <h1 style={{fontFamily:"CrimsonText-Regular"}}>Varieties</h1>
                    <br></br>
                    <br></br>

                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Indian</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li> Dal Fry & Jeera Rice </li>
                    <li> Dal Makhani & Basmati Rice </li>
                    <li> Dal Tadka & Basmati Rice  </li>
                    <li> Amritsari Chole & Basmati Rice </li>
                    <li> Aloo Mutter & Basmati Rice  </li>
                    <li> Paneer Butter Masala & Basmati Rice  </li>
                    <li> Palak Paneer & Jeera Rice </li>
                    <li> Rajma Masala & Jeera Rice </li>
                    <li> Veg Kadhai & Steamed Rice </li>
                    <li> Veg Korma & Steamed Rice</li>

                    </ul>

                    <br></br>
                    <br></br>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Continental</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li>Alfredo pasta sauce & pasta  </li>
                    <li>Basil pesto sauce & pasta </li>
                    <li>Arrabbiata pasta sauce & pasta</li>
                  
                    </ul>

                   

                    </Col>
                    
                    <Col xs="12" sm="12" md="5" lg="6" >   
                    <Row>
                        <Col  className="nopadding ">
                            <img alt="image" src={img1} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    <Row  style={{marginTop:"3%"}}>                       
                        <Col xs="6" sm="6" md="6" lg="6" className="justify-content-center nopadding ">
                        <img alt="image" src={img2} width="100%" height="100%"/>
                        </Col>
                        <Col xs="6" sm="6" md="6" lg="6" style={{paddingRight:"0%"}}>
                        <img alt="image" src={img3} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    
                    </Col>
                    
                </Row>

                {/* This is second row  */}
                <Row style={{padding:"0% 15% 6%"}} >
                   
                    
                    <Col xs="12" sm="12" md="7" lg="6" >   
                    
                    <Row >                       
                        <Col xs="6" sm="6" md="6" lg="6"  style={{paddingLeft:"0%"}} className="justify-content-center  ">
                        <img alt="image" src={img4} width="100%" height="100%"/>
                        </Col>
                        <Col xs="6" sm="6" md="6" lg="6" className="nopadding">
                        <img alt="image" src={img5} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"3%"}}>
                        <Col  className="nopadding">
                        <img alt="image" src={img6} width="100%" height="100%"/>
                        </Col>
                    </Row>
                    </Col>

                    <Col xs="12" sm="12" md="5" lg="6" style={{padding:"8% 0% 8% 7%"}}>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Vegan Range</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li> Chicken Jalfraize & Basmati Rice   </li>
                    <li> Chicken Tikka Masala & Basmati Rice   </li>
                    <li> Chicken Vindaloo & Basmati Rice   </li>
                    <li> Butter Chicken & Basmati Rice   </li>
                    <li> Mutton Roganjosh & Basmati Rice   </li>
                    <li> Mutton Kareemi Masala & Basmati Rice   </li>
                    <li> Mutton Saagwala & Basmati Rice</li>

                    </ul>

                    <br></br>
                    <br></br>
                    <h2 style={{fontFamily:"CrimsonText-Italic"}}>Asian</h2>
                    <ul style={{paddingLeft:"inherit",fontFamily:"Montserrat-Medium",fontSize:"14pt"}}>
                        
                    <li>Green Thai with Jasmine Rice </li>
                    <li>Red Thai with Jasmine Rice </li>
                    <li>Veg Chilli & Fried Rice </li>
                    <li>Veg Manchurian & Fried Rice </li>
                    <li>Veg Manchurian & Noodles </li>
                    <li>Veg. Red Thai Curry & Steamed Rice </li>
                    <li>Veg Schezwan & Fried Rice </li>
                  
                    </ul>

                   

                    </Col>
                    
                </Row>

                                
                {/* This div containes the 3rd tile */}
                <div style={{padding:"5%",backgroundColor:"black",alignItems:"center"}}>
                    <div style={
                    { 
                        alignItems: "center",
                        justifyContent: "center",
                        display:"flex",
                        flexDirection:"column",
                        width:"100%"
                    }}>

                    <div style={{width:"100%",alignItems:"center",justifyContent:"center",display:"flex"}}>
                    <img alt="image" src={dots} width="5%"/> 
                    </div>
                    
                    <div style={{marginTop:"1%"}}>
                    <h1 style={{fontFamily:'CrimsonText-SemiBold',color:'whitesmoke',fontSize:"270%"}}>Packaging Sizes</h1>           
                    </div>
                    </div>
                   

                <Row style={{backgroundColor:"black",alignItems:"center",marginTop:"2%",paddingLeft:"15%",paddingRight:"15%"}}>
                    <Col sm style={{paddingRight:"0%",paddingLeft:"0%"}}>
                    <Card  className="card-mod" style={{backgroundColor:"#ff2e34",alignItems:"center"}}>
                        <Card.Body style={{padding:"25% 3%"}}>
                            <Card.Title style={{fontFamily:"Montserrat-Medium",fontSize:"300%",marginBottom:"0%"}}>
                                200g+200g
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"200%",textAlign:"center"}}>
                                <Row>
                                    <Col className="justify-content-center"> Curry</Col>
                                    <Col className="justify-content-center">Rice</Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>


                    <Col sm style={{marginLeft:"1%",paddingLeft:"0%",paddingRight:"0%"}}>
                    <Card className="card-mod" style={{backgroundColor:"#00aa53",alignItems:"center"}}>
                        <Card.Body style={{padding:"27% 3%"}}>
                            <Card.Title style={{fontFamily:"CrimsonText-Regular",fontSize:"200%",marginBottom:"0%"}}>
                            Retort Combo
                            </Card.Title>
                            <Card.Text style={{fontFamily:"CrimsonText-Regular",fontSize:"200%",textAlign:"center"}}>
                            <Row>
                                    <Col className="justify-content-center"> Meal Trays</Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                   

                </Row>
                    
                </div>
            </>
        )
    }
}
export default ComboComponent;